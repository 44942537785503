
import { defineComponent } from "vue";
import store from "@/store/index";
import { ContextMenu, PageContextMenu } from "@/store/util/types";
import { Page } from "@/store/pages/types";

export default defineComponent({
  name: "PageMenu",
  props: {
    menu: {
      type: PageContextMenu,
      required: true,
    },
  },
  mounted: function () {
    (this.$refs["content"] as any).focus();
  },
  computed: {
    _page: function (): Page {
      return store.getters["pages/getPage"](this.menu.page);
    },
  },
  methods: {
    deletePage: async function () {
      store.dispatch("util/closeMenu");
      if (this.menu.page == this.$route.params.pageId) {
        await this.$router.push(`/-/${store.getters["workspace/getId"]}`);
      }
      store.dispatch("pages/delete", this.menu.page);
    },
    copyMention: function () {
      navigator.clipboard.writeText(
        '<page-mention data-page="' + this.menu.page + '"></page-mention>'
      );
      store.dispatch("util/closeMenu");
    },
    copyView: function () {
      navigator.clipboard.writeText(
        '<page-note data-page="' +
          this.menu.page +
          '" data-open="1"></page-note>'
      );
      store.dispatch("util/closeMenu");
    },
    handleBlur: function (event: any) {
      if (!(this.$refs["content"] as any).contains(event.relatedTarget)) {
        store.dispatch("util/closeMenu");
      }
    },
    close: function () {
      store.dispatch("util/closeMenu");
    },
  },
});

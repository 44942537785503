
import { defineComponent } from "vue";
import store from "@/store/index";
import {
  EmojiSelectionContextMenu,
  ContextMenu,
  TeamHelper,
  PackageHelper,
  ProfileHelper,
} from "@/store/util/types";

export default defineComponent({
  name: "WorkspaceHelperNode",
  data() {
    return {
      warning: false,
    };
  },
  computed: {
    _name: {
      get() {
        return store.getters["workspace/getName"];
      },
      set(newName) {
        store.dispatch("workspace/setName", newName);
      },
    },
    _icon: function () {
      return store.getters["workspace/getIcon"];
    },
  },
  methods: {
    openTeamHelper: function () {
      store.dispatch("util/pushHelper", new TeamHelper());
    },
    openPackageHelper: function () {
      store.dispatch("util/pushHelper", new PackageHelper());
    },
    openProfileHelper: function () {
      store.dispatch("util/pushHelper", new ProfileHelper());
    },
    saveName: function () {
      store.dispatch("workspace/save", ["name"]);
    },
    selectIcon: function () {
      store.dispatch(
        "util/pushMenu",
        new EmojiSelectionContextMenu(
          ContextMenu.getLocation(event),
          (emoji: string) => {
            store.dispatch("workspace/setIcon", emoji);
            store.dispatch("workspace/save", ["icon"]);
          }
        )
      );
    },
    closeHelper: function () {
      store.dispatch("util/closeHelper");
    },
    deleteWorkspace: function () {
      store.dispatch("user/deleteWorkspace");
      this.$router.push("/-");
      store.dispatch("util/closeHelper");
    },
  },
});

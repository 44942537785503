import store from "@/store/index";
import { Editor } from "@tiptap/vue-3";
//import { Relationship } from "@/store/relationships/types";
import { SuggestionItem, SuggestionGroup } from "@/components/editor/types";
//import { PropertyType, Property } from "@/store/relationships/types";
import { PalletColor } from "@/store/pages/types";

import PageMenu from "@/components/util/menus/PageMenu.vue";
//import AuthorMenu from "@/components/util/menus/AuthorMenu.vue";
//import { MessagePreview } from "@/store/messages/types";
//import MaterialTypeMenu from "@/components/util/menus/MaterialTypeMenu.vue";
import EditorCommandMenu from "@/components/util/menus/EditorCommandMenu.vue";
import HighlightMenu from "@/components/util/menus/HighlightMenu.vue";
//import EditorMentionMenu from "@/components/util/menus/EditorMentionMenu.vue";
//import EditorEqualsMenu from "@/components/util/menus/EditorEqualsMenu.vue";
//import MaterialMenu from "@/components/util/menus/MaterialMenu.vue";
//import MessageMenu from "@/components/util/menus/MessageMenu.vue";
//import RelationshipMenu from "@/components/util/menus/RelationshipMenu.vue";
import WorkspaceListMenu from "@/components/util/menus/WorkspaceListMenu.vue";
import EmojiSelectionMenu from "@/components/util/menus/EmojiSelectionMenu.vue";
//import RelationshipSelectionMenu from "@/components/util/menus/RelationshipSelectionMenu.vue";
//import MaterialsDrawerMenu from "@/components/util/menus/MaterialsDrawerMenu.vue";
//import NewRelationshipMenu from "@/components/util/menus/NewRelationshipMenu.vue";
//import PropertyTypeMenu from "@/components/util/menus/PropertyTypeMenu.vue";
//import EllipsisMenu from "@/components/util/menus/EllipsisMenu.vue";
//import ViewTypeMenu from "@/components/util/menus/ViewTypeMenu.vue";
//import ViewMenu from "@/components/util/menus/ViewMenu.vue";

//import MaterialHelperNode from "@/components/util/helpers/MaterialHelperNode.vue";
//import LeadsListHelperNode from "@/components/util/helpers/LeadsListHelperNode.vue";
//import MessageHelperNode from "@/components/util/helpers/MessageHelperNode.vue";
//import RelationshipHelperNode from "@/components/util/helpers/RelationshipHelperNode.vue";
import WorkspaceHelperNode from "@/components/util/helpers/settings/WorkspaceHelperNode.vue";
import TeamHelperNode from "@/components/util/helpers/settings/TeamHelperNode.vue";
import ProfileHelperNode from "@/components/util/helpers/settings/ProfileHelperNode.vue";
import PackageHelperNode from "@/components/util/helpers/settings/PackageHelperNode.vue";
/*
import UpgradeToTeamsNode from "@/components/util/helpers/settings/UpgradeToTeamsNode.vue";
import EditBillingNode from "@/components/util/helpers/settings/EditBillingNode.vue";
*/

/*
import WorkspaceDisplayComponent from "@/components/util/displays/WorkspaceDisplayComponent.vue";
import MessageDisplayComponent from "@/components/util/displays/MessageDisplayComponent.vue";
*/

export interface UtilState {
  contextmenu: null | ContextMenu;
  helper: null | Helper;

  savingJobs: number;
  savingWorker: Worker;
}

/* Menus */
export enum ContextMenuType {
  Page,
  Author,
  MaterialType,
  EditorSlash,
  Highlight,
  EditorMention,
  Mention,
  EditorEquals,
  Material,
  Message,
  Relationship,
  WorkspaceList,
  EmojiSelection,
  RelationshipSelection,
  Lead,
  MaterialsDrawer,
  NewRelationship,
  PropertyType,
  Ellipsis,
  ViewType,
  View,
}

export abstract class ContextMenu {
  location: Location;

  // readonlys
  abstract readonly type: ContextMenuType;
  abstract readonly component: any;

  constructor(location: Location) {
    this.location = location;
  }

  abstract keyHandler(event: KeyboardEvent): void;

  static getLocation(event: any, height = 0) {
    return {
      x: event.pageX || event.clientX,
      y: event.pageY || event.clientY,
      height: height,
    };
  }
}

export class PageContextMenu extends ContextMenu {
  page: string;

  type = ContextMenuType.Page;
  component = PageMenu;

  constructor(location: Location, page: string) {
    super(location);
    this.page = page;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

/*
export class AuthorContextMenu extends ContextMenu {
  options: Array<{
    key: string;
    icon: Array<string>;
    title: string;
    select: () => void;
  }>;

  type = ContextMenuType.Author;
  component = AuthorMenu;

  constructor(
    location: Location,
    options: Array<{
      key: string;
      icon: Array<string>;
      title: string;
      select: () => void;
    }>
  ) {
    super(location);
    this.options = options;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class MaterialTypeContextMenu extends ContextMenu {
  material: string;

  type = ContextMenuType.MaterialType;
  component = MaterialTypeMenu;

  constructor(location: Location, material: string) {
    super(location);
    this.material = material;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}
*/

export class EditorCommandContextMenu extends ContextMenu {
  options: Array<any>;
  query: string;
  selected: number;
  command: any;

  type = ContextMenuType.EditorSlash;
  component = EditorCommandMenu;

  constructor(
    location: Location,
    options: Array<() => SuggestionItem>,
    query: string,
    command: any
  ) {
    super(location);
    this.options = options;
    this.query = query;
    this.selected = 0;
    this.command = command;
  }

  items(): Array<SuggestionItem> {
    return this.options
      .map((option) => option())
      .flat()
      .filter((item: SuggestionItem) => {
        return (
          item.title.toLowerCase().startsWith(this.query.toLowerCase()) ||
          item.key.toLowerCase().startsWith(this.query.toLowerCase())
        );
      });
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Enter") {
      event.preventDefault();
      this.command(this.items()[this.selected]);
    } else if (event.key == "ArrowUp") {
      event.preventDefault();
      this.selected =
        (this.selected + this.items().length - 1) % this.items().length;
    } else if (event.key == "ArrowDown") {
      event.preventDefault();
      this.selected =
        (this.selected + this.items().length + 1) % this.items().length;
    }
  }
}

export class HighlightContextMenu extends ContextMenu {
  editor: Editor;

  type = ContextMenuType.Highlight;
  component = HighlightMenu;

  constructor(location: Location, editor: Editor) {
    super(location);
    this.editor = editor;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}
/*
export class EditorMentionContextMenu extends ContextMenu {
  items: Array<SuggestionItem>;
  selected: number;
  command: any;

  type = ContextMenuType.EditorMention;
  component = EditorMentionMenu;

  constructor(location: Location, items: Array<SuggestionItem>, command: any) {
    super(location);
    this.items = items;
    this.selected = 0;
    this.command = command;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Enter") {
      event.preventDefault();
      this.command(this.items[this.selected]);
    } else if (event.key == "ArrowUp") {
      event.preventDefault();
      do {
        this.selected =
          (this.selected + this.items.length - 1) % this.items.length;
      } while (this.items[this.selected].key == "group-heading");
    } else if (event.key == "ArrowDown") {
      event.preventDefault();
      do {
        this.selected =
          (this.selected + this.items.length + 1) % this.items.length;
      } while (this.items[this.selected].key == "group-heading");
    }
  }
}

export class MentionContextMenu extends ContextMenu {
  member: string;
  pinned: any;
  pinMention: any;

  type = ContextMenuType.Mention;
  component = MentionMenu;

  constructor(
    location: Location,
    member: string,
    pinned: any,
    pinMention: any
  ) {
    super(location);
    this.member = member;
    this.pinned = pinned;
    this.pinMention = pinMention;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class MaterialContextMenu extends ContextMenu {
  material: string;

  type = ContextMenuType.Material;
  component = MaterialMenu;

  constructor(location: Location, material: string) {
    super(location);
    this.material = material;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class MessageContextMenu extends ContextMenu {
  message: MessagePreview;

  type = ContextMenuType.Message;
  component = MessageMenu;

  constructor(location: Location, message: MessagePreview) {
    super(location);
    this.message = message;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class RelationshipContextMenu extends ContextMenu {
  relationship: string;

  // readonlys
  type = ContextMenuType.Relationship;
  component = RelationshipMenu;

  constructor(location: Location, relationship: string) {
    super(location);
    this.relationship = relationship;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}
*/

export class WorkspaceListContextMenu extends ContextMenu {
  type = ContextMenuType.WorkspaceList;
  component = WorkspaceListMenu;

  constructor(location: Location) {
    super(location);
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class EmojiSelectionContextMenu extends ContextMenu {
  select: (emoji: string) => void;

  type = ContextMenuType.EmojiSelection;
  component = EmojiSelectionMenu;

  constructor(location: Location, select: (emoji: string) => void) {
    super(location);
    this.select = select;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

/*
export class RelationshipSelectionContextMenu extends ContextMenu {
  select: (relationship: string) => void;
  selected: number;
  exclude: Array<string>;
  search: string;
  options: Array<SuggestionItem>;

  type = ContextMenuType.RelationshipSelection;
  component = RelationshipSelectionMenu;

  constructor(
    location: Location,
    select: (relationship: string) => void,
    exclude: Array<string>
  ) {
    super(location);
    this.select = select;
    this.selected = 0;
    this.exclude = exclude;
    this.search = "";
    this.options = store.getters["relationships/getAll"]
      .filter((id: string) => !this.exclude.includes(id))
      .map((id: string) => {
        return {
          key: store.getters["relationships/getEmail"](id),
          title: store.getters["relationships/getName"](id),
          icon: null,
          group: SuggestionGroup.Relationships,
          command: () => select(id),
        };
      });
  }

  items(): Array<SuggestionItem> {
    if (this.search.length == 0) return [];
    const out = this.options.filter((item: SuggestionItem) => {
      return (
        item.title.toLowerCase().startsWith(this.search.toLowerCase()) ||
        item.key.toLowerCase().startsWith(this.search.toLowerCase())
      );
    });
    // create new
    out.push({
      key: "create",
      title: "save: " + this.search,
      icon: ["fal", "plus-square"],
      group: SuggestionGroup.Priority,
      command: () => {
        if (
          this.search
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          store
            .dispatch(
              "relationships/create",
              Relationship.create("", "", { twitter: "", email: this.search })
            )
            .then((relationship: Relationship) => {
              this.select(relationship.id);
            });
        } else {
          store
            .dispatch(
              "relationships/create",
              Relationship.create("", this.search, {
                twitter: "",
                email: "",
              })
            )
            .then((relationship: Relationship) => {
              setTimeout(() => {
                this.select(relationship.id);
              }, 200);
            });
        }
      },
    });
    return out;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Enter") {
      event.preventDefault();
      this.items()[this.selected].command();
    } else if (event.key == "ArrowUp") {
      event.preventDefault();
      this.selected =
        (this.selected + this.items().length - 1) % this.items().length;
    } else if (event.key == "ArrowDown") {
      event.preventDefault();
      this.selected =
        (this.selected + this.items().length + 1) % this.items().length;
    }
  }
}

export class MaterialsDrawerContextMenu extends ContextMenu {
  page: string;

  type = ContextMenuType.MaterialsDrawer;
  component = MaterialsDrawerMenu;

  constructor(location: Location, page: string) {
    super(location);
    this.page = page;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class NewRelationshipContextMenu extends ContextMenu {
  relationship: Relationship;

  type = ContextMenuType.NewRelationship;
  component = NewRelationshipMenu;

  constructor(location: Location, relationship: Relationship) {
    super(location);
    this.relationship = relationship;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class PropertyTypeContextMenu extends ContextMenu {
  property: Property | PropertyRule;
  setType: (type: PropertyType) => void;
  setKey: (key: string) => void;
  setColor: (color: PalletColor) => void;
  save: () => void;

  type = ContextMenuType.PropertyType;
  component = PropertyTypeMenu;

  constructor(
    location: Location,
    property: Property,
    setType: (type: PropertyType) => void,
    setKey: (key: string) => void,
    setColor: (color: PalletColor) => void,
    save: () => void
  ) {
    super(location);
    this.property = property;
    this.setType = setType;
    this.setKey = setKey;
    this.setColor = setColor;
    this.save = save;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class EllipsisContextMenu extends ContextMenu {
  messages: Array<MessagePreview>;

  type = ContextMenuType.Ellipsis;
  component = EllipsisMenu;

  constructor(location: Location, messages: Array<MessagePreview>) {
    super(location);
    this.messages = messages;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}


export class ViewTypeContextMenu extends ContextMenu {
  selectView: (type: PageType) => void;
  type = ContextMenuType.ViewType;
  component = ViewTypeMenu;

  constructor(location: Location, selectView: (type: PageType) => void) {
    super(location);
    this.selectView = selectView;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}

export class ViewContextMenu extends ContextMenu {
  page: string;
  view: number;
  type = ContextMenuType.View;
  component = ViewMenu;

  constructor(location: Location, page: string, view: number) {
    super(location);
    this.page = page;
    this.view = view;
  }

  keyHandler(event: KeyboardEvent) {
    if (event.key == "Escape") store.dispatch("util/closeMenu");
  }
}
*/

export interface Location {
  x: number;
  y: number;
  height: number;
}

/* Displays */

/*
export enum DisplayType {
  Workspace,
  Message,
}

export abstract class Display {
  // readonlys
  abstract readonly type: DisplayType;
  abstract readonly component: any;

  constructor() {
    const a = "a";
  }
}

export class WorkspaceDisplay extends Display {
  //readonlys
  type = DisplayType.Workspace;
  component = WorkspaceDisplayComponent;

  constructor() {
    super();
  }
}

export class MessageDisplay extends Display {
  message: MessagePreview;

  type = DisplayType.Message;
  component = MessageDisplayComponent;

  constructor(message: MessagePreview) {
    super();
    this.message = message;
  }
}
*/

/* Helper */
export enum HelperType {
  Page,
  Material,
  ListLeads,
  Thread,
  Relationship,
  Workspace,
  Team,
  Profile,
  Package,
  UpgradeToTeams,
  EditBilling,
}

export abstract class Helper {
  // readonlys
  abstract readonly type: HelperType;
  abstract readonly component: any;

  constructor() {
    const a = "a";
  }
}

/*
export class MaterialHelper extends Helper {
  material: string;

  type = HelperType.Material;
  component = MaterialHelperNode;

  constructor(material: string) {
    super();
    this.material = material;
  }
}

export class ListLeadsHelper extends Helper {
  page: string;

  type = HelperType.ListLeads;
  component = LeadsListHelperNode;

  constructor(page: string) {
    super();
    this.page = page;
  }
}

export class MessageHelper extends Helper {
  message: MessagePreview;

  // readonlys
  type = HelperType.Thread;
  component = MessageHelperNode;

  constructor(message: MessagePreview) {
    super();
    this.message = message;
  }
}

export class RelationshipHelper extends Helper {
  relationship: string;

  // readonlys
  type = HelperType.Relationship;
  component = RelationshipHelperNode;

  constructor(relationship: string) {
    super();
    this.relationship = relationship;
  }
}
*/

export class WorkspaceHelper extends Helper {
  // readonlys
  type = HelperType.Workspace;
  component = WorkspaceHelperNode;

  constructor() {
    super();
  }
}

export class TeamHelper extends Helper {
  // readonlys
  type = HelperType.Team;
  component = TeamHelperNode;

  constructor() {
    super();
  }
}

export class ProfileHelper extends Helper {
  // readonlys
  type = HelperType.Profile;
  component = ProfileHelperNode;

  constructor() {
    super();
  }
}

export class PackageHelper extends Helper {
  // readonlys
  type = HelperType.Package;
  component = PackageHelperNode;

  constructor() {
    super();
  }
}

/*
export class UpgradeToTeamsHelper extends Helper {
  // readonlys
  type = HelperType.UpgradeToTeams;
  component = UpgradeToTeamsNode;

  constructor() {
    super();
  }
}

export class EditBillingHelper extends Helper {
  // readonlys
  type = HelperType.EditBilling;
  component = EditBillingNode;

  constructor() {
    super();
  }
}
*/

export const randomEmoji = function () {
  return emojis[Math.floor((emojis.length / 2) * Math.random())].emoji;
};

export const getEmojis = async (arr: Array<any>) => {
  emojis.forEach(async (emoji) => {
    arr.push(emoji);
  });
};

export const emojis = [
  {
    emoji: "😀",
    key: "Grinning Face",
  },
  {
    emoji: "😁",
    key: "Beaming Face With Smiling Eyes",
  },
  {
    emoji: "😂",
    key: "Face With Tears of Joy",
  },
  {
    emoji: "🤣",
    key: "Rolling on the Floor Laughing",
  },
  {
    emoji: "😃",
    key: "Grinning Face With Big Eyes",
  },
  {
    emoji: "😄",
    key: "Grinning Face With Smiling Eyes",
  },
  {
    emoji: "😅",
    key: "Grinning Face With Sweat",
  },
  {
    emoji: "😆",
    key: "Grinning Squinting Face",
  },
  {
    emoji: "😉",
    key: "Winking Face",
  },
  {
    emoji: "😊",
    key: "Smiling Face With Smiling Eyes",
  },
  {
    emoji: "😋",
    key: "Face Savoring Food",
  },
  {
    emoji: "😎",
    key: "Smiling Face With Sunglasses",
  },
  {
    emoji: "😍",
    key: "Smiling Face With Heart-Eyes",
  },
  {
    emoji: "😘",
    key: "Face Blowing a Kiss",
  },
  {
    emoji: "😗",
    key: "Kissing Face",
  },
  {
    emoji: "😙",
    key: "Kissing Face With Smiling Eyes",
  },
  {
    emoji: "😚",
    key: "Kissing Face With Closed Eyes",
  },
  {
    emoji: "☺",
    key: "Smiling Face",
  },
  {
    emoji: "🙂",
    key: "Slightly Smiling Face",
  },
  {
    emoji: "🤗",
    key: "Hugging Face",
  },
  {
    emoji: "🤩",
    key: "Star-Struck",
  },
  {
    emoji: "🤔",
    key: "Thinking Face",
  },
  {
    emoji: "🤨",
    key: "Face With Raised Eyebrow",
  },
  {
    emoji: "😐",
    key: "Neutral Face",
  },
  {
    emoji: "😑",
    key: "Expressionless Face",
  },
  {
    emoji: "😶",
    key: "Face Without Mouth",
  },
  {
    emoji: "🙄",
    key: "Face With Rolling Eyes",
  },
  {
    emoji: "😏",
    key: "Smirking Face",
  },
  {
    emoji: "😣",
    key: "Persevering Face",
  },
  {
    emoji: "😥",
    key: "Sad but Relieved Face",
  },
  {
    emoji: "😮",
    key: "Face With Open Mouth",
  },
  {
    emoji: "🤐",
    key: "Zipper-Mouth Face",
  },
  {
    emoji: "😯",
    key: "Hushed Face",
  },
  {
    emoji: "😪",
    key: "Sleepy Face",
  },
  {
    emoji: "😫",
    key: "Tired Face",
  },
  {
    emoji: "😴",
    key: "Sleeping Face",
  },
  {
    emoji: "😌",
    key: "Relieved Face",
  },
  {
    emoji: "😛",
    key: "Face With Tongue",
  },
  {
    emoji: "😜",
    key: "Winking Face With Tongue",
  },
  {
    emoji: "😝",
    key: "Squinting Face With Tongue",
  },
  {
    emoji: "🤤",
    key: "Drooling Face",
  },
  {
    emoji: "😒",
    key: "Unamused Face",
  },
  {
    emoji: "😓",
    key: "Downcast Face With Sweat",
  },
  {
    emoji: "😔",
    key: "Pensive Face",
  },
  {
    emoji: "😕",
    key: "Confused Face",
  },
  {
    emoji: "🙃",
    key: "Upside-Down Face",
  },
  {
    emoji: "🤑",
    key: "Money-Mouth Face",
  },
  {
    emoji: "😲",
    key: "Astonished Face",
  },
  {
    emoji: "☹",
    key: "Frowning Face",
  },
  {
    emoji: "🙁",
    key: "Slightly Frowning Face",
  },
  {
    emoji: "😖",
    key: "Confounded Face",
  },
  {
    emoji: "😞",
    key: "Disappointed Face",
  },
  {
    emoji: "😟",
    key: "Worried Face",
  },
  {
    emoji: "😤",
    key: "Face With Steam From Nose",
  },
  {
    emoji: "😢",
    key: "Crying Face",
  },
  {
    emoji: "😭",
    key: "Loudly Crying Face",
  },
  {
    emoji: "😦",
    key: "Frowning Face With Open Mouth",
  },
  {
    emoji: "😧",
    key: "Anguished Face",
  },
  {
    emoji: "😨",
    key: "Fearful Face",
  },
  {
    emoji: "😩",
    key: "Weary Face",
  },
  {
    emoji: "🤯",
    key: "Exploding Head",
  },
  {
    emoji: "😬",
    key: "Grimacing Face",
  },
  {
    emoji: "😰",
    key: "Anxious Face With Sweat",
  },
  {
    emoji: "😱",
    key: "Face Screaming in Fear",
  },
  {
    emoji: "😳",
    key: "Flushed Face",
  },
  {
    emoji: "🤪",
    key: "Zany Face",
  },
  {
    emoji: "😵",
    key: "Dizzy Face",
  },
  {
    emoji: "😡",
    key: "Pouting Face",
  },
  {
    emoji: "😠",
    key: "Angry Face",
  },
  {
    emoji: "🤬",
    key: "Face With Symbols on Mouth",
  },
  {
    emoji: "😷",
    key: "Face With Medical Mask",
  },
  {
    emoji: "🤒",
    key: "Face With Thermometer",
  },
  {
    emoji: "🤕",
    key: "Face With Head-Bandage",
  },
  {
    emoji: "🤢",
    key: "Nauseated Face",
  },
  {
    emoji: "🤮",
    key: "Face Vomiting",
  },
  {
    emoji: "🤧",
    key: "Sneezing Face",
  },
  {
    emoji: "😇",
    key: "Smiling Face With Halo",
  },
  {
    emoji: "🤠",
    key: "Cowboy Hat Face",
  },
  {
    emoji: "🤡",
    key: "Clown Face",
  },
  {
    emoji: "🤥",
    key: "Lying Face",
  },
  {
    emoji: "🤫",
    key: "Shushing Face",
  },
  {
    emoji: "🤭",
    key: "Face With Hand Over Mouth",
  },
  {
    emoji: "🧐",
    key: "Face With Monocle",
  },
  {
    emoji: "🤓",
    key: "Nerd Face",
  },
  {
    emoji: "😈",
    key: "Smiling Face With Horns",
  },
  {
    emoji: "👿",
    key: "Angry Face With Horns",
  },
  {
    emoji: "👹",
    key: "Ogre",
  },
  {
    emoji: "👺",
    key: "Goblin",
  },
  {
    emoji: "💀",
    key: "Skull",
  },
  {
    emoji: "👻",
    key: "Ghost",
  },
  {
    emoji: "👽",
    key: "Alien",
  },
  {
    emoji: "🤖",
    key: "Robot Face",
  },
  {
    emoji: "💩",
    key: "Pile of Poo",
  },
  {
    emoji: "😺",
    key: "Grinning Cat Face",
  },
  {
    emoji: "😸",
    key: "Grinning Cat Face With Smiling Eyes",
  },
  {
    emoji: "😹",
    key: "Cat Face With Tears of Joy",
  },
  {
    emoji: "😻",
    key: "Smiling Cat Face With Heart-Eyes",
  },
  {
    emoji: "😼",
    key: "Cat Face With Wry Smile",
  },
  {
    emoji: "😽",
    key: "Kissing Cat Face",
  },
  {
    emoji: "🙀",
    key: "Weary Cat Face",
  },
  {
    emoji: "😿",
    key: "Crying Cat Face",
  },
  {
    emoji: "😾",
    key: "Pouting Cat Face",
  },
  {
    emoji: "👶",
    key: "Baby",
  },
  {
    emoji: "👦",
    key: "Boy",
  },
  {
    emoji: "👧",
    key: "Girl",
  },
  {
    emoji: "👨",
    key: "Man",
  },
  {
    emoji: "👩",
    key: "Woman",
  },
  {
    emoji: "👴",
    key: "Old Man",
  },
  {
    emoji: "👵",
    key: "Old Woman",
  },
  {
    emoji: "👨‍⚕️",
    key: "Man Health Worker",
  },
  {
    emoji: "👩‍⚕️",
    key: "Woman Health Worker",
  },
  {
    emoji: "👨‍🎓",
    key: "Man Student",
  },
  {
    emoji: "👩‍🎓",
    key: "Woman Student",
  },
  {
    emoji: "👨‍⚖️",
    key: "Man Judge",
  },
  {
    emoji: "👩‍⚖️",
    key: "Woman Judge",
  },
  {
    emoji: "👨‍🌾",
    key: "Man Farmer",
  },
  {
    emoji: "👩‍🌾",
    key: "Woman Farmer",
  },
  {
    emoji: "👨‍🍳",
    key: "Man Cook",
  },
  {
    emoji: "👩‍🍳",
    key: "Woman Cook",
  },
  {
    emoji: "👨‍🔧",
    key: "Man Mechanic",
  },
  {
    emoji: "👩‍🔧",
    key: "Woman Mechanic",
  },
  {
    emoji: "👨‍🏭",
    key: "Man Factory Worker",
  },
  {
    emoji: "👩‍🏭",
    key: "Woman Factory Worker",
  },
  {
    emoji: "👨‍💼",
    key: "Man Office Worker",
  },
  {
    emoji: "👩‍💼",
    key: "Woman Office Worker",
  },
  {
    emoji: "👨‍🔬",
    key: "Man Scientist",
  },
  {
    emoji: "👩‍🔬",
    key: "Woman Scientist",
  },
  {
    emoji: "👨‍💻",
    key: "Man Technologist",
  },
  {
    emoji: "👩‍💻",
    key: "Woman Technologist",
  },
  {
    emoji: "👨‍🎤",
    key: "Man Singer",
  },
  {
    emoji: "👩‍🎤",
    key: "Woman Singer",
  },
  {
    emoji: "👨‍🎨",
    key: "Man Artist",
  },
  {
    emoji: "👩‍🎨",
    key: "Woman Artist",
  },
  {
    emoji: "👨‍✈️",
    key: "Man Pilot",
  },
  {
    emoji: "👩‍✈️",
    key: "Woman Pilot",
  },
  {
    emoji: "👨‍🚀",
    key: "Man Astronaut",
  },
  {
    emoji: "👩‍🚀",
    key: "Woman Astronaut",
  },
  {
    emoji: "👨‍🚒",
    key: "Man Firefighter",
  },
  {
    emoji: "👩‍🚒",
    key: "Woman Firefighter",
  },
  {
    emoji: "👮",
    key: "Police Officer",
  },
  {
    emoji: "👮‍♂️",
    key: "Man Police Officer",
  },
  {
    emoji: "👮‍♀️",
    key: "Woman Police Officer",
  },
  {
    emoji: "🕵",
    key: "Detective",
  },
  {
    emoji: "🕵️‍♂️",
    key: "Man Detective",
  },
  {
    emoji: "🕵️‍♀️",
    key: "Woman Detective",
  },
  {
    emoji: "💂",
    key: "Guard",
  },
  {
    emoji: "💂‍♂️",
    key: "Man Guard",
  },
  {
    emoji: "💂‍♀️",
    key: "Woman Guard",
  },
  {
    emoji: "👷",
    key: "Construction Worker",
  },
  {
    emoji: "👷‍♂️",
    key: "Man Construction Worker",
  },
  {
    emoji: "👷‍♀️",
    key: "Woman Construction Worker",
  },
  {
    emoji: "🤴",
    key: "Prince",
  },
  {
    emoji: "👸",
    key: "Princess",
  },
  {
    emoji: "👳",
    key: "Person Wearing Turban",
  },
  {
    emoji: "👳‍♂️",
    key: "Man Wearing Turban",
  },
  {
    emoji: "👳‍♀️",
    key: "Woman Wearing Turban",
  },
  {
    emoji: "👲",
    key: "Man With Chinese Cap",
  },
  {
    emoji: "🧕",
    key: "Woman With Headscarf",
  },
  {
    emoji: "🧔",
    key: "Bearded Person",
  },
  {
    emoji: "👱",
    key: "Blond-Haired Person",
  },
  {
    emoji: "👱‍♂️",
    key: "Blond-Haired Man",
  },
  {
    emoji: "👱‍♀️",
    key: "Blond-Haired Woman",
  },
  {
    emoji: "🤵",
    key: "Man in Tuxedo",
  },
  {
    emoji: "👰",
    key: "Bride With Veil",
  },
  {
    emoji: "🤰",
    key: "Pregnant Woman",
  },
  {
    emoji: "🤱",
    key: "Breast-Feeding",
  },
  {
    emoji: "👼",
    key: "Baby Angel",
  },
  {
    emoji: "🎅",
    key: "Santa Claus",
  },
  {
    emoji: "🤶",
    key: "Mrs. Claus",
  },
  {
    emoji: "🧙‍♀️",
    key: "Woman Mage",
  },
  {
    emoji: "🧙‍♂️",
    key: "Man Mage",
  },
  {
    emoji: "🧚‍♀️",
    key: "Woman Fairy",
  },
  {
    emoji: "🧚‍♂️",
    key: "Man Fairy",
  },
  {
    emoji: "🧛‍♀️",
    key: "Woman Vampire",
  },
  {
    emoji: "🧛‍♂️",
    key: "Man Vampire",
  },
  {
    emoji: "🧜‍♀️",
    key: "Mermaid",
  },
  {
    emoji: "🧜‍♂️",
    key: "Merman",
  },
  {
    emoji: "🧝‍♀️",
    key: "Woman Elf",
  },
  {
    emoji: "🧝‍♂️",
    key: "Man Elf",
  },
  {
    emoji: "🧞‍♀️",
    key: "Woman Genie",
  },
  {
    emoji: "🧞‍♂️",
    key: "Man Genie",
  },
  {
    emoji: "🧟‍♀️",
    key: "Woman Zombie",
  },
  {
    emoji: "🧟‍♂️",
    key: "Man Zombie",
  },
  {
    emoji: "🙍",
    key: "Person Frowning",
  },
  {
    emoji: "🙍‍♂️",
    key: "Man Frowning",
  },
  {
    emoji: "🙍‍♀️",
    key: "Woman Frowning",
  },
  {
    emoji: "🙎",
    key: "Person Pouting",
  },
  {
    emoji: "🙎‍♂️",
    key: "Man Pouting",
  },
  {
    emoji: "🙎‍♀️",
    key: "Woman Pouting",
  },
  {
    emoji: "🙅",
    key: "Person Gesturing No",
  },
  {
    emoji: "🙅‍♂️",
    key: "Man Gesturing No",
  },
  {
    emoji: "🙅‍♀️",
    key: "Woman Gesturing No",
  },
  {
    emoji: "🙆",
    key: "Person Gesturing OK",
  },
  {
    emoji: "🙆‍♂️",
    key: "Man Gesturing OK",
  },
  {
    emoji: "🙆‍♀️",
    key: "Woman Gesturing OK",
  },
  {
    emoji: "💁",
    key: "Person Tipping Hand",
  },
  {
    emoji: "💁‍♂️",
    key: "Man Tipping Hand",
  },
  {
    emoji: "💁‍♀️",
    key: "Woman Tipping Hand",
  },
  {
    emoji: "🙋",
    key: "Person Raising Hand",
  },
  {
    emoji: "🙋‍♂️",
    key: "Man Raising Hand",
  },
  {
    emoji: "🙋‍♀️",
    key: "Woman Raising Hand",
  },
  {
    emoji: "🙇",
    key: "Person Bowing",
  },
  {
    emoji: "🙇‍♂️",
    key: "Man Bowing",
  },
  {
    emoji: "🙇‍♀️",
    key: "Woman Bowing",
  },
  {
    emoji: "🤦",
    key: "Person Facepalming",
  },
  {
    emoji: "🤦‍♂️",
    key: "Man Facepalming",
  },
  {
    emoji: "🤦‍♀️",
    key: "Woman Facepalming",
  },
  {
    emoji: "🤷",
    key: "Person Shrugging",
  },
  {
    emoji: "🤷‍♂️",
    key: "Man Shrugging",
  },
  {
    emoji: "🤷‍♀️",
    key: "Woman Shrugging",
  },
  {
    emoji: "💆",
    key: "Person Getting Massage",
  },
  {
    emoji: "💆‍♂️",
    key: "Man Getting Massage",
  },
  {
    emoji: "💆‍♀️",
    key: "Woman Getting Massage",
  },
  {
    emoji: "💇",
    key: "Person Getting Haircut",
  },
  {
    emoji: "💇‍♂️",
    key: "Man Getting Haircut",
  },
  {
    emoji: "💇‍♀️",
    key: "Woman Getting Haircut",
  },
  {
    emoji: "🚶",
    key: "Person Walking",
  },
  {
    emoji: "🚶‍♂️",
    key: "Man Walking",
  },
  {
    emoji: "🚶‍♀️",
    key: "Woman Walking",
  },
  {
    emoji: "🏃",
    key: "Person Running",
  },
  {
    emoji: "🏃‍♂️",
    key: "Man Running",
  },
  {
    emoji: "🏃‍♀️",
    key: "Woman Running",
  },
  {
    emoji: "💃",
    key: "Woman Dancing",
  },
  {
    emoji: "🕺",
    key: "Man Dancing",
  },
  {
    emoji: "👯",
    key: "People With Bunny Ears",
  },
  {
    emoji: "👯‍♂️",
    key: "Men With Bunny Ears",
  },
  {
    emoji: "👯‍♀️",
    key: "Women With Bunny Ears",
  },
  {
    emoji: "🧖‍♀️",
    key: "Woman in Steamy Room",
  },
  {
    emoji: "🧖‍♂️",
    key: "Man in Steamy Room",
  },
  {
    emoji: "🕴",
    key: "Man in Suit Levitating",
  },
  {
    emoji: "🗣",
    key: "Speaking Head",
  },
  {
    emoji: "👤",
    key: "Bust in Silhouette",
  },
  {
    emoji: "👥",
    key: "Busts in Silhouette",
  },
  {
    emoji: "👫",
    key: "Man and Woman Holding Hands",
  },
  {
    emoji: "👬",
    key: "Two Men Holding Hands",
  },
  {
    emoji: "👭",
    key: "Two Women Holding Hands",
  },
  {
    emoji: "💏",
    key: "Kiss",
  },
  {
    emoji: "👨‍❤️‍💋‍👨",
    key: "Kiss: Man",
  },
  {
    emoji: "👩‍❤️‍💋‍👩",
    key: "Kiss: Woman",
  },
  {
    emoji: "💑",
    key: "Couple With Heart",
  },
  {
    emoji: "👨‍❤️‍👨",
    key: "Couple With Heart: Man",
  },
  {
    emoji: "👩‍❤️‍👩",
    key: "Couple With Heart: Woman",
  },
  {
    emoji: "👪",
    key: "Family",
  },
  {
    emoji: "👨‍👩‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👩‍👧",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👩‍👧‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👩‍👦‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👩‍👧‍👧",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👨‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👨‍👧",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👨‍👧‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👨‍👦‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👨‍👧‍👧",
    key: "Family: Man",
  },
  {
    emoji: "👩‍👩‍👦",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👩‍👧",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👩‍👧‍👦",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👩‍👦‍👦",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👩‍👧‍👧",
    key: "Family: Woman",
  },
  {
    emoji: "👨‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👦‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👧",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👧‍👦",
    key: "Family: Man",
  },
  {
    emoji: "👨‍👧‍👧",
    key: "Family: Man",
  },
  {
    emoji: "👩‍👦",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👦‍👦",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👧",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👧‍👦",
    key: "Family: Woman",
  },
  {
    emoji: "👩‍👧‍👧",
    key: "Family: Woman",
  },
  {
    emoji: "🤳",
    key: "Selfie",
  },
  {
    emoji: "💪",
    key: "Flexed Biceps",
  },
  {
    emoji: "👈",
    key: "Backhand Index Pointing Left",
  },
  {
    emoji: "👉",
    key: "Backhand Index Pointing Right",
  },
  {
    emoji: "☝",
    key: "Index Pointing Up",
  },
  {
    emoji: "👆",
    key: "Backhand Index Pointing Up",
  },
  {
    emoji: "🖕",
    key: "Middle Finger",
  },
  {
    emoji: "👇",
    key: "Backhand Index Pointing Down",
  },
  {
    emoji: "✌",
    key: "Victory Hand",
  },
  {
    emoji: "🤞",
    key: "Crossed Fingers",
  },
  {
    emoji: "🖖",
    key: "Vulcan Salute",
  },
  {
    emoji: "🤘",
    key: "Sign of the Horns",
  },
  {
    emoji: "🖐",
    key: "Hand With Fingers Splayed",
  },
  {
    emoji: "✋",
    key: "Raised Hand",
  },
  {
    emoji: "👌",
    key: "OK Hand",
  },
  {
    emoji: "👍",
    key: "Thumbs Up",
  },
  {
    emoji: "👎",
    key: "Thumbs Down",
  },
  {
    emoji: "✊",
    key: "Raised Fist",
  },
  {
    emoji: "👊",
    key: "Oncoming Fist",
  },
  {
    emoji: "🤛",
    key: "Left-Facing Fist",
  },
  {
    emoji: "🤜",
    key: "Right-Facing Fist",
  },
  {
    emoji: "🤚",
    key: "Raised Back of Hand",
  },
  {
    emoji: "👋",
    key: "Waving Hand",
  },
  {
    emoji: "🤟",
    key: "Love-You Gesture",
  },
  {
    emoji: "✍",
    key: "Writing Hand",
  },
  {
    emoji: "👏",
    key: "Clapping Hands",
  },
  {
    emoji: "👐",
    key: "Open Hands",
  },
  {
    emoji: "🙌",
    key: "Raising Hands",
  },
  {
    emoji: "🤲",
    key: "Palms Up Together",
  },
  {
    emoji: "🙏",
    key: "Folded Hands",
  },
  {
    emoji: "🤝",
    key: "Handshake",
  },
  {
    emoji: "💅",
    key: "Nail Polish",
  },
  {
    emoji: "👂",
    key: "Ear",
  },
  {
    emoji: "👃",
    key: "Nose",
  },
  {
    emoji: "👣",
    key: "Footprints",
  },
  {
    emoji: "👀",
    key: "Eyes",
  },
  {
    emoji: "👁",
    key: "Eye",
  },
  {
    emoji: "🧠",
    key: "Brain",
  },
  {
    emoji: "👅",
    key: "Tongue",
  },
  {
    emoji: "👄",
    key: "Mouth",
  },
  {
    emoji: "💋",
    key: "Kiss Mark",
  },
  {
    emoji: "👓",
    key: "Glasses",
  },
  {
    emoji: "🕶",
    key: "Sunglasses",
  },
  {
    emoji: "👔",
    key: "Necktie",
  },
  {
    emoji: "👕",
    key: "T-Shirt",
  },
  {
    emoji: "👖",
    key: "Jeans",
  },
  {
    emoji: "🧣",
    key: "Scarf",
  },
  {
    emoji: "🧤",
    key: "Gloves",
  },
  {
    emoji: "🧥",
    key: "Coat",
  },
  {
    emoji: "🧦",
    key: "Socks",
  },
  {
    emoji: "👗",
    key: "Dress",
  },
  {
    emoji: "👘",
    key: "Kimono",
  },
  {
    emoji: "👙",
    key: "Bikini",
  },
  {
    emoji: "👚",
    key: "Woman’s Clothes",
  },
  {
    emoji: "👛",
    key: "Purse",
  },
  {
    emoji: "👜",
    key: "Handbag",
  },
  {
    emoji: "👝",
    key: "Clutch Bag",
  },
  {
    emoji: "🎒",
    key: "School Backpack",
  },
  {
    emoji: "👞",
    key: "Man’s Shoe",
  },
  {
    emoji: "👟",
    key: "Running Shoe",
  },
  {
    emoji: "👠",
    key: "High-Heeled Shoe",
  },
  {
    emoji: "👡",
    key: "Woman’s Sandal",
  },
  {
    emoji: "👢",
    key: "Woman’s Boot",
  },
  {
    emoji: "👑",
    key: "Crown",
  },
  {
    emoji: "👒",
    key: "Woman’s Hat",
  },
  {
    emoji: "🎩",
    key: "Top Hat",
  },
  {
    emoji: "🎓",
    key: "Graduation Cap",
  },
  {
    emoji: "🧢",
    key: "Billed Cap",
  },
  {
    emoji: "⛑",
    key: "Rescue Worker’s Helmet",
  },
  {
    emoji: "💄",
    key: "Lipstick",
  },
  {
    emoji: "💍",
    key: "Ring",
  },
  {
    emoji: "🌂",
    key: "Closed Umbrella",
  },
  {
    emoji: "☂",
    key: "Umbrella",
  },
  {
    emoji: "💼",
    key: "Briefcase",
  },
  {
    emoji: "🙈",
    key: "See-No-Evil Monkey",
  },
  {
    emoji: "🙉",
    key: "Hear-No-Evil Monkey",
  },
  {
    emoji: "🙊",
    key: "Speak-No-Evil Monkey",
  },
  {
    emoji: "💥",
    key: "Collision",
  },
  {
    emoji: "💦",
    key: "Sweat Droplets",
  },
  {
    emoji: "💨",
    key: "Dashing Away",
  },
  {
    emoji: "💫",
    key: "Dizzy",
  },
  {
    emoji: "🐵",
    key: "Monkey Face",
  },
  {
    emoji: "🐒",
    key: "Monkey",
  },
  {
    emoji: "🦍",
    key: "Gorilla",
  },
  {
    emoji: "🐶",
    key: "Dog Face",
  },
  {
    emoji: "🐕",
    key: "Dog",
  },
  {
    emoji: "🐩",
    key: "Poodle",
  },
  {
    emoji: "🐺",
    key: "Wolf Face",
  },
  {
    emoji: "🦊",
    key: "Fox Face",
  },
  {
    emoji: "🐱",
    key: "Cat Face",
  },
  {
    emoji: "🐈",
    key: "Cat",
  },
  {
    emoji: "🦁",
    key: "Lion Face",
  },
  {
    emoji: "🐯",
    key: "Tiger Face",
  },
  {
    emoji: "🐅",
    key: "Tiger",
  },
  {
    emoji: "🐆",
    key: "Leopard",
  },
  {
    emoji: "🐴",
    key: "Horse Face",
  },
  {
    emoji: "🐎",
    key: "Horse",
  },
  {
    emoji: "🦄",
    key: "Unicorn Face",
  },
  {
    emoji: "🦓",
    key: "Zebra",
  },
  {
    emoji: "🐮",
    key: "Cow Face",
  },
  {
    emoji: "🐂",
    key: "Ox",
  },
  {
    emoji: "🐃",
    key: "Water Buffalo",
  },
  {
    emoji: "🐄",
    key: "Cow",
  },
  {
    emoji: "🐷",
    key: "Pig Face",
  },
  {
    emoji: "🐖",
    key: "Pig",
  },
  {
    emoji: "🐗",
    key: "Boar",
  },
  {
    emoji: "🐽",
    key: "Pig Nose",
  },
  {
    emoji: "🐏",
    key: "Ram",
  },
  {
    emoji: "🐑",
    key: "Ewe",
  },
  {
    emoji: "🐐",
    key: "Goat",
  },
  {
    emoji: "🐪",
    key: "Camel",
  },
  {
    emoji: "🐫",
    key: "Two-Hump Camel",
  },
  {
    emoji: "🦒",
    key: "Giraffe",
  },
  {
    emoji: "🐘",
    key: "Elephant",
  },
  {
    emoji: "🦏",
    key: "Rhinoceros",
  },
  {
    emoji: "🐭",
    key: "Mouse Face",
  },
  {
    emoji: "🐁",
    key: "Mouse",
  },
  {
    emoji: "🐀",
    key: "Rat",
  },
  {
    emoji: "🐹",
    key: "Hamster Face",
  },
  {
    emoji: "🐰",
    key: "Rabbit Face",
  },
  {
    emoji: "🐇",
    key: "Rabbit",
  },
  {
    emoji: "🐿",
    key: "Chipmunk",
  },
  {
    emoji: "🦔",
    key: "Hedgehog",
  },
  {
    emoji: "🦇",
    key: "Bat",
  },
  {
    emoji: "🐻",
    key: "Bear Face",
  },
  {
    emoji: "🐨",
    key: "Koala",
  },
  {
    emoji: "🐼",
    key: "Panda Face",
  },
  {
    emoji: "🐾",
    key: "Paw Prints",
  },
  {
    emoji: "🦃",
    key: "Turkey",
  },
  {
    emoji: "🐔",
    key: "Chicken",
  },
  {
    emoji: "🐓",
    key: "Rooster",
  },
  {
    emoji: "🐣",
    key: "Hatching Chick",
  },
  {
    emoji: "🐤",
    key: "Baby Chick",
  },
  {
    emoji: "🐥",
    key: "Front-Facing Baby Chick",
  },
  {
    emoji: "🐦",
    key: "Bird",
  },
  {
    emoji: "🐧",
    key: "Penguin",
  },
  {
    emoji: "🕊",
    key: "Dove",
  },
  {
    emoji: "🦅",
    key: "Eagle",
  },
  {
    emoji: "🦆",
    key: "Duck",
  },
  {
    emoji: "🦉",
    key: "Owl",
  },
  {
    emoji: "🐸",
    key: "Frog Face",
  },
  {
    emoji: "🐊",
    key: "Crocodile",
  },
  {
    emoji: "🐢",
    key: "Turtle",
  },
  {
    emoji: "🦎",
    key: "Lizard",
  },
  {
    emoji: "🐍",
    key: "Snake",
  },
  {
    emoji: "🐲",
    key: "Dragon Face",
  },
  {
    emoji: "🐉",
    key: "Dragon",
  },
  {
    emoji: "🦕",
    key: "Sauropod",
  },
  {
    emoji: "🦖",
    key: "T-Rex",
  },
  {
    emoji: "🐳",
    key: "Spouting Whale",
  },
  {
    emoji: "🐋",
    key: "Whale",
  },
  {
    emoji: "🐬",
    key: "Dolphin",
  },
  {
    emoji: "🐟",
    key: "Fish",
  },
  {
    emoji: "🐠",
    key: "Tropical Fish",
  },
  {
    emoji: "🐡",
    key: "Blowfish",
  },
  {
    emoji: "🦈",
    key: "Shark",
  },
  {
    emoji: "🐙",
    key: "Octopus",
  },
  {
    emoji: "🐚",
    key: "Spiral Shell",
  },
  {
    emoji: "🦀",
    key: "Crab",
  },
  {
    emoji: "🦐",
    key: "Shrimp",
  },
  {
    emoji: "🦑",
    key: "Squid",
  },
  {
    emoji: "🐌",
    key: "Snail",
  },
  {
    emoji: "🦋",
    key: "Butterfly",
  },
  {
    emoji: "🐛",
    key: "Bug",
  },
  {
    emoji: "🐜",
    key: "Ant",
  },
  {
    emoji: "🐝",
    key: "Honeybee",
  },
  {
    emoji: "🐞",
    key: "Lady Beetle",
  },
  {
    emoji: "🦗",
    key: "Cricket",
  },
  {
    emoji: "🕷",
    key: "Spider",
  },
  {
    emoji: "🕸",
    key: "Spider Web",
  },
  {
    emoji: "🦂",
    key: "Scorpion",
  },
  {
    emoji: "💐",
    key: "Bouquet",
  },
  {
    emoji: "🌸",
    key: "Cherry Blossom",
  },
  {
    emoji: "💮",
    key: "White Flower",
  },
  {
    emoji: "🏵",
    key: "Rosette",
  },
  {
    emoji: "🌹",
    key: "Rose",
  },
  {
    emoji: "🥀",
    key: "Wilted Flower",
  },
  {
    emoji: "🌺",
    key: "Hibiscus",
  },
  {
    emoji: "🌻",
    key: "Sunflower",
  },
  {
    emoji: "🌼",
    key: "Blossom",
  },
  {
    emoji: "🌷",
    key: "Tulip",
  },
  {
    emoji: "🌱",
    key: "Seedling",
  },
  {
    emoji: "🌲",
    key: "Evergreen Tree",
  },
  {
    emoji: "🌳",
    key: "Deciduous Tree",
  },
  {
    emoji: "🌴",
    key: "Palm Tree",
  },
  {
    emoji: "🌵",
    key: "Cactus",
  },
  {
    emoji: "🌾",
    key: "Sheaf of Rice",
  },
  {
    emoji: "🌿",
    key: "Herb",
  },
  {
    emoji: "☘",
    key: "Shamrock",
  },
  {
    emoji: "🍀",
    key: "Four Leaf Clover",
  },
  {
    emoji: "🍁",
    key: "Maple Leaf",
  },
  {
    emoji: "🍂",
    key: "Fallen Leaf",
  },
  {
    emoji: "🍃",
    key: "Leaf Fluttering in Wind",
  },
  {
    emoji: "🍄",
    key: "Mushroom",
  },
  {
    emoji: "🌰",
    key: "Chestnut",
  },
  {
    emoji: "🌍",
    key: "Globe Showing Europe-Africa",
  },
  {
    emoji: "🌎",
    key: "Globe Showing Americas",
  },
  {
    emoji: "🌏",
    key: "Globe Showing Asia-Australia",
  },
  {
    emoji: "🌐",
    key: "Globe With Meridians",
  },
  {
    emoji: "🌑",
    key: "New Moon",
  },
  {
    emoji: "🌒",
    key: "Waxing Crescent Moon",
  },
  {
    emoji: "🌓",
    key: "First Quarter Moon",
  },
  {
    emoji: "🌔",
    key: "Waxing Gibbous Moon",
  },
  {
    emoji: "🌕",
    key: "Full Moon",
  },
  {
    emoji: "🌖",
    key: "Waning Gibbous Moon",
  },
  {
    emoji: "🌗",
    key: "Last Quarter Moon",
  },
  {
    emoji: "🌘",
    key: "Waning Crescent Moon",
  },
  {
    emoji: "🌙",
    key: "Crescent Moon",
  },
  {
    emoji: "🌚",
    key: "New Moon Face",
  },
  {
    emoji: "🌛",
    key: "First Quarter Moon Face",
  },
  {
    emoji: "🌜",
    key: "Last Quarter Moon Face",
  },
  {
    emoji: "☀",
    key: "Sun",
  },
  {
    emoji: "🌝",
    key: "Full Moon Face",
  },
  {
    emoji: "🌞",
    key: "Sun With Face",
  },
  {
    emoji: "⭐",
    key: "White Medium Star",
  },
  {
    emoji: "🌟",
    key: "Glowing Star",
  },
  {
    emoji: "🌠",
    key: "Shooting Star",
  },
  {
    emoji: "☁",
    key: "Cloud",
  },
  {
    emoji: "⛅",
    key: "Sun Behind Cloud",
  },
  {
    emoji: "⛈",
    key: "Cloud With Lightning and Rain",
  },
  {
    emoji: "🌤",
    key: "Sun Behind Small Cloud",
  },
  {
    emoji: "🌥",
    key: "Sun Behind Large Cloud",
  },
  {
    emoji: "🌦",
    key: "Sun Behind Rain Cloud",
  },
  {
    emoji: "🌧",
    key: "Cloud With Rain",
  },
  {
    emoji: "🌨",
    key: "Cloud With Snow",
  },
  {
    emoji: "🌩",
    key: "Cloud With Lightning",
  },
  {
    emoji: "🌪",
    key: "Tornado",
  },
  {
    emoji: "🌫",
    key: "Fog",
  },
  {
    emoji: "🌬",
    key: "Wind Face",
  },
  {
    emoji: "🌈",
    key: "Rainbow",
  },
  {
    emoji: "☂",
    key: "Umbrella",
  },
  {
    emoji: "☔",
    key: "Umbrella With Rain Drops",
  },
  {
    emoji: "⚡",
    key: "High Voltage",
  },
  {
    emoji: "❄",
    key: "Snowflake",
  },
  {
    emoji: "☃",
    key: "Snowman",
  },
  {
    emoji: "⛄",
    key: "Snowman Without Snow",
  },
  {
    emoji: "☄",
    key: "Comet",
  },
  {
    emoji: "🔥",
    key: "Fire",
  },
  {
    emoji: "💧",
    key: "Droplet",
  },
  {
    emoji: "🌊",
    key: "Water Wave",
  },
  {
    emoji: "🎄",
    key: "Christmas Tree",
  },
  {
    emoji: "✨",
    key: "Sparkles",
  },
  {
    emoji: "🎋",
    key: "Tanabata Tree",
  },
  {
    emoji: "🎍",
    key: "Pine Decoration",
  },
  {
    emoji: "🍇",
    key: "Grapes",
  },
  {
    emoji: "🍈",
    key: "Melon",
  },
  {
    emoji: "🍉",
    key: "Watermelon",
  },
  {
    emoji: "🍊",
    key: "Tangerine",
  },
  {
    emoji: "🍋",
    key: "Lemon",
  },
  {
    emoji: "🍌",
    key: "Banana",
  },
  {
    emoji: "🍍",
    key: "Pineapple",
  },
  {
    emoji: "🍎",
    key: "Red Apple",
  },
  {
    emoji: "🍏",
    key: "Green Apple",
  },
  {
    emoji: "🍐",
    key: "Pear",
  },
  {
    emoji: "🍑",
    key: "Peach",
  },
  {
    emoji: "🍒",
    key: "Cherries",
  },
  {
    emoji: "🍓",
    key: "Strawberry",
  },
  {
    emoji: "🥝",
    key: "Kiwi Fruit",
  },
  {
    emoji: "🍅",
    key: "Tomato",
  },
  {
    emoji: "🥥",
    key: "Coconut",
  },
  {
    emoji: "🥑",
    key: "Avocado",
  },
  {
    emoji: "🍆",
    key: "Eggplant",
  },
  {
    emoji: "🥔",
    key: "Potato",
  },
  {
    emoji: "🥕",
    key: "Carrot",
  },
  {
    emoji: "🌽",
    key: "Ear of Corn",
  },
  {
    emoji: "🌶",
    key: "Hot Pepper",
  },
  {
    emoji: "🥒",
    key: "Cucumber",
  },
  {
    emoji: "🥦",
    key: "Broccoli",
  },
  {
    emoji: "🍄",
    key: "Mushroom",
  },
  {
    emoji: "🥜",
    key: "Peanuts",
  },
  {
    emoji: "🌰",
    key: "Chestnut",
  },
  {
    emoji: "🍞",
    key: "Bread",
  },
  {
    emoji: "🥐",
    key: "Croissant",
  },
  {
    emoji: "🥖",
    key: "Baguette Bread",
  },
  {
    emoji: "🥨",
    key: "Pretzel",
  },
  {
    emoji: "🥞",
    key: "Pancakes",
  },
  {
    emoji: "🧀",
    key: "Cheese Wedge",
  },
  {
    emoji: "🍖",
    key: "Meat on Bone",
  },
  {
    emoji: "🍗",
    key: "Poultry Leg",
  },
  {
    emoji: "🥩",
    key: "Cut of Meat",
  },
  {
    emoji: "🥓",
    key: "Bacon",
  },
  {
    emoji: "🍔",
    key: "Hamburger",
  },
  {
    emoji: "🍟",
    key: "French Fries",
  },
  {
    emoji: "🍕",
    key: "Pizza",
  },
  {
    emoji: "🌭",
    key: "Hot Dog",
  },
  {
    emoji: "🥪",
    key: "Sandwich",
  },
  {
    emoji: "🌮",
    key: "Taco",
  },
  {
    emoji: "🌯",
    key: "Burrito",
  },
  {
    emoji: "🍳",
    key: "Cooking",
  },
  {
    emoji: "🍲",
    key: "Pot of Food",
  },
  {
    emoji: "🥣",
    key: "Bowl With Spoon",
  },
  {
    emoji: "🥗",
    key: "Green Salad",
  },
  {
    emoji: "🍿",
    key: "Popcorn",
  },
  {
    emoji: "🥫",
    key: "Canned Food",
  },
  {
    emoji: "🍱",
    key: "Bento Box",
  },
  {
    emoji: "🍘",
    key: "Rice Cracker",
  },
  {
    emoji: "🍙",
    key: "Rice Ball",
  },
  {
    emoji: "🍚",
    key: "Cooked Rice",
  },
  {
    emoji: "🍛",
    key: "Curry Rice",
  },
  {
    emoji: "🍜",
    key: "Steaming Bowl",
  },
  {
    emoji: "🍝",
    key: "Spaghetti",
  },
  {
    emoji: "🍠",
    key: "Roasted Sweet Potato",
  },
  {
    emoji: "🍢",
    key: "Oden",
  },
  {
    emoji: "🍣",
    key: "Sushi",
  },
  {
    emoji: "🍤",
    key: "Fried Shrimp",
  },
  {
    emoji: "🍥",
    key: "Fish Cake With Swirl",
  },
  {
    emoji: "🍡",
    key: "Dango",
  },
  {
    emoji: "🥟",
    key: "Dumpling",
  },
  {
    emoji: "🥠",
    key: "Fortune Cookie",
  },
  {
    emoji: "🥡",
    key: "Takeout Box",
  },
  {
    emoji: "🍦",
    key: "Soft Ice Cream",
  },
  {
    emoji: "🍧",
    key: "Shaved Ice",
  },
  {
    emoji: "🍨",
    key: "Ice Cream",
  },
  {
    emoji: "🍩",
    key: "Doughnut",
  },
  {
    emoji: "🍪",
    key: "Cookie",
  },
  {
    emoji: "🎂",
    key: "Birthday Cake",
  },
  {
    emoji: "🍰",
    key: "Shortcake",
  },
  {
    emoji: "🥧",
    key: "Pie",
  },
  {
    emoji: "🍫",
    key: "Chocolate Bar",
  },
  {
    emoji: "🍬",
    key: "Candy",
  },
  {
    emoji: "🍭",
    key: "Lollipop",
  },
  {
    emoji: "🍮",
    key: "Custard",
  },
  {
    emoji: "🍯",
    key: "Honey Pot",
  },
  {
    emoji: "🍼",
    key: "Baby Bottle",
  },
  {
    emoji: "🥛",
    key: "Glass of Milk",
  },
  {
    emoji: "☕",
    key: "Hot Beverage",
  },
  {
    emoji: "🍵",
    key: "Teacup Without Handle",
  },
  {
    emoji: "🍶",
    key: "Sake",
  },
  {
    emoji: "🍾",
    key: "Bottle With Popping Cork",
  },
  {
    emoji: "🍷",
    key: "Wine Glass",
  },
  {
    emoji: "🍸",
    key: "Cocktail Glass",
  },
  {
    emoji: "🍹",
    key: "Tropical Drink",
  },
  {
    emoji: "🍺",
    key: "Beer Mug",
  },
  {
    emoji: "🍻",
    key: "Clinking Beer Mugs",
  },
  {
    emoji: "🥂",
    key: "Clinking Glasses",
  },
  {
    emoji: "🥃",
    key: "Tumbler Glass",
  },
  {
    emoji: "🥤",
    key: "Cup With Straw",
  },
  {
    emoji: "🥢",
    key: "Chopsticks",
  },
  {
    emoji: "🍽",
    key: "Fork and Knife With Plate",
  },
  {
    emoji: "🍴",
    key: "Fork and Knife",
  },
  {
    emoji: "🥄",
    key: "Spoon",
  },
  {
    emoji: "👾",
    key: "Alien Monster",
  },
  {
    emoji: "🧗‍♀️",
    key: "Woman Climbing",
  },
  {
    emoji: "🧗‍♂️",
    key: "Man Climbing",
  },
  {
    emoji: "🧘‍♀️",
    key: "Woman in Lotus Position",
  },
  {
    emoji: "🧘‍♂️",
    key: "Man in Lotus Position",
  },
  {
    emoji: "🕴",
    key: "Man in Suit Levitating",
  },
  {
    emoji: "🏇",
    key: "Horse Racing",
  },
  {
    emoji: "⛷",
    key: "Skier",
  },
  {
    emoji: "🏂",
    key: "Snowboarder",
  },
  {
    emoji: "🏌",
    key: "Person Golfing",
  },
  {
    emoji: "🏌️‍♂️",
    key: "Man Golfing",
  },
  {
    emoji: "🏌️‍♀️",
    key: "Woman Golfing",
  },
  {
    emoji: "🏄",
    key: "Person Surfing",
  },
  {
    emoji: "🏄‍♂️",
    key: "Man Surfing",
  },
  {
    emoji: "🏄‍♀️",
    key: "Woman Surfing",
  },
  {
    emoji: "🚣",
    key: "Person Rowing Boat",
  },
  {
    emoji: "🚣‍♂️",
    key: "Man Rowing Boat",
  },
  {
    emoji: "🚣‍♀️",
    key: "Woman Rowing Boat",
  },
  {
    emoji: "🏊",
    key: "Person Swimming",
  },
  {
    emoji: "🏊‍♂️",
    key: "Man Swimming",
  },
  {
    emoji: "🏊‍♀️",
    key: "Woman Swimming",
  },
  {
    emoji: "⛹",
    key: "Person Bouncing Ball",
  },
  {
    emoji: "⛹️‍♂️",
    key: "Man Bouncing Ball",
  },
  {
    emoji: "⛹️‍♀️",
    key: "Woman Bouncing Ball",
  },
  {
    emoji: "🏋",
    key: "Person Lifting Weights",
  },
  {
    emoji: "🏋️‍♂️",
    key: "Man Lifting Weights",
  },
  {
    emoji: "🏋️‍♀️",
    key: "Woman Lifting Weights",
  },
  {
    emoji: "🚴",
    key: "Person Biking",
  },
  {
    emoji: "🚴‍♂️",
    key: "Man Biking",
  },
  {
    emoji: "🚴‍♀️",
    key: "Woman Biking",
  },
  {
    emoji: "🚵",
    key: "Person Mountain Biking",
  },
  {
    emoji: "🚵‍♂️",
    key: "Man Mountain Biking",
  },
  {
    emoji: "🚵‍♀️",
    key: "Woman Mountain Biking",
  },
  {
    emoji: "🤸",
    key: "Person Cartwheeling",
  },
  {
    emoji: "🤸‍♂️",
    key: "Man Cartwheeling",
  },
  {
    emoji: "🤸‍♀️",
    key: "Woman Cartwheeling",
  },
  {
    emoji: "🤼",
    key: "People Wrestling",
  },
  {
    emoji: "🤼‍♂️",
    key: "Men Wrestling",
  },
  {
    emoji: "🤼‍♀️",
    key: "Women Wrestling",
  },
  {
    emoji: "🤽",
    key: "Person Playing Water Polo",
  },
  {
    emoji: "🤽‍♂️",
    key: "Man Playing Water Polo",
  },
  {
    emoji: "🤽‍♀️",
    key: "Woman Playing Water Polo",
  },
  {
    emoji: "🤾",
    key: "Person Playing Handball",
  },
  {
    emoji: "🤾‍♂️",
    key: "Man Playing Handball",
  },
  {
    emoji: "🤾‍♀️",
    key: "Woman Playing Handball",
  },
  {
    emoji: "🤹",
    key: "Person Juggling",
  },
  {
    emoji: "🤹‍♂️",
    key: "Man Juggling",
  },
  {
    emoji: "🤹‍♀️",
    key: "Woman Juggling",
  },
  {
    emoji: "🎪",
    key: "Circus Tent",
  },
  {
    emoji: "🎗",
    key: "Reminder Ribbon",
  },
  {
    emoji: "🎟",
    key: "Admission Tickets",
  },
  {
    emoji: "🎫",
    key: "Ticket",
  },
  {
    emoji: "🎖",
    key: "Military Medal",
  },
  {
    emoji: "🏆",
    key: "Trophy",
  },
  {
    emoji: "🏅",
    key: "Sports Medal",
  },
  {
    emoji: "🥇",
    key: "1st Place Medal",
  },
  {
    emoji: "🥈",
    key: "2nd Place Medal",
  },
  {
    emoji: "🥉",
    key: "3rd Place Medal",
  },
  {
    emoji: "⚽",
    key: "Soccer Ball",
  },
  {
    emoji: "⚾",
    key: "Baseball",
  },
  {
    emoji: "🏀",
    key: "Basketball",
  },
  {
    emoji: "🏐",
    key: "Volleyball",
  },
  {
    emoji: "🏈",
    key: "American Football",
  },
  {
    emoji: "🏉",
    key: "Rugby Football",
  },
  {
    emoji: "🎾",
    key: "Tennis",
  },
  {
    emoji: "🎳",
    key: "Bowling",
  },
  {
    emoji: "🏏",
    key: "Cricket Game",
  },
  {
    emoji: "🏑",
    key: "Field Hockey",
  },
  {
    emoji: "🏒",
    key: "Ice Hockey",
  },
  {
    emoji: "🏓",
    key: "Ping Pong",
  },
  {
    emoji: "🏸",
    key: "Badminton",
  },
  {
    emoji: "🥊",
    key: "Boxing Glove",
  },
  {
    emoji: "🥋",
    key: "Martial Arts Uniform",
  },
  {
    emoji: "⛳",
    key: "Flag in Hole",
  },
  {
    emoji: "⛸",
    key: "Ice Skate",
  },
  {
    emoji: "🎣",
    key: "Fishing Pole",
  },
  {
    emoji: "🎽",
    key: "Running Shirt",
  },
  {
    emoji: "🎿",
    key: "Skis",
  },
  {
    emoji: "🛷",
    key: "Sled",
  },
  {
    emoji: "🥌",
    key: "Curling Stone",
  },
  {
    emoji: "🎯",
    key: "Direct Hit",
  },
  {
    emoji: "🎱",
    key: "Pool 8 Ball",
  },
  {
    emoji: "🎮",
    key: "Video Game",
  },
  {
    emoji: "🎰",
    key: "Slot Machine",
  },
  {
    emoji: "🎲",
    key: "Game Die",
  },
  {
    emoji: "🎭",
    key: "Performing Arts",
  },
  {
    emoji: "🎨",
    key: "Artist Palette",
  },
  {
    emoji: "🎼",
    key: "Musical Score",
  },
  {
    emoji: "🎤",
    key: "Microphone",
  },
  {
    emoji: "🎧",
    key: "Headphone",
  },
  {
    emoji: "🎷",
    key: "Saxophone",
  },
  {
    emoji: "🎸",
    key: "Guitar",
  },
  {
    emoji: "🎹",
    key: "Musical Keyboard",
  },
  {
    emoji: "🎺",
    key: "Trumpet",
  },
  {
    emoji: "🎻",
    key: "Violin",
  },
  {
    emoji: "🥁",
    key: "Drum",
  },
  {
    emoji: "🎬",
    key: "Clapper Board",
  },
  {
    emoji: "🏹",
    key: "Bow and Arrow",
  },
  {
    emoji: "🚣",
    key: "Person Rowing Boat",
  },
  {
    emoji: "🏎",
    key: "Racing Car",
  },
  {
    emoji: "🏍",
    key: "Motorcycle",
  },
  {
    emoji: "🗾",
    key: "Map of Japan",
  },
  {
    emoji: "🏔",
    key: "Snow-Capped Mountain",
  },
  {
    emoji: "⛰",
    key: "Mountain",
  },
  {
    emoji: "🌋",
    key: "Volcano",
  },
  {
    emoji: "🗻",
    key: "Mount Fuji",
  },
  {
    emoji: "🏕",
    key: "Camping",
  },
  {
    emoji: "🏖",
    key: "Beach With Umbrella",
  },
  {
    emoji: "🏜",
    key: "Desert",
  },
  {
    emoji: "🏝",
    key: "Desert Island",
  },
  {
    emoji: "🏞",
    key: "National Park",
  },
  {
    emoji: "🏟",
    key: "Stadium",
  },
  {
    emoji: "🏛",
    key: "Classical Building",
  },
  {
    emoji: "🏗",
    key: "Building Construction",
  },
  {
    emoji: "🏘",
    key: "Houses",
  },
  {
    emoji: "🏚",
    key: "Derelict House",
  },
  {
    emoji: "🏠",
    key: "House",
  },
  {
    emoji: "🏡",
    key: "House With Garden",
  },
  {
    emoji: "🏢",
    key: "Office Building",
  },
  {
    emoji: "🏣",
    key: "Japanese Post Office",
  },
  {
    emoji: "🏤",
    key: "Post Office",
  },
  {
    emoji: "🏥",
    key: "Hospital",
  },
  {
    emoji: "🏦",
    key: "Bank",
  },
  {
    emoji: "🏨",
    key: "Hotel",
  },
  {
    emoji: "🏩",
    key: "Love Hotel",
  },
  {
    emoji: "🏪",
    key: "Convenience Store",
  },
  {
    emoji: "🏫",
    key: "School",
  },
  {
    emoji: "🏬",
    key: "Department Store",
  },
  {
    emoji: "🏭",
    key: "Factory",
  },
  {
    emoji: "🏯",
    key: "Japanese Castle",
  },
  {
    emoji: "🏰",
    key: "Castle",
  },
  {
    emoji: "💒",
    key: "Wedding",
  },
  {
    emoji: "🗼",
    key: "Tokyo Tower",
  },
  {
    emoji: "🗽",
    key: "Statue of Liberty",
  },
  {
    emoji: "⛪",
    key: "Church",
  },
  {
    emoji: "🕌",
    key: "Mosque",
  },
  {
    emoji: "🕍",
    key: "Synagogue",
  },
  {
    emoji: "⛩",
    key: "Shinto Shrine",
  },
  {
    emoji: "🕋",
    key: "Kaaba",
  },
  {
    emoji: "⛲",
    key: "Fountain",
  },
  {
    emoji: "⛺",
    key: "Tent",
  },
  {
    emoji: "🌁",
    key: "Foggy",
  },
  {
    emoji: "🌃",
    key: "Night With Stars",
  },
  {
    emoji: "🏙",
    key: "Cityscape",
  },
  {
    emoji: "🌄",
    key: "Sunrise Over Mountains",
  },
  {
    emoji: "🌅",
    key: "Sunrise",
  },
  {
    emoji: "🌆",
    key: "Cityscape at Dusk",
  },
  {
    emoji: "🌇",
    key: "Sunset",
  },
  {
    emoji: "🌉",
    key: "Bridge at Night",
  },
  {
    emoji: "🌌",
    key: "Milky Way",
  },
  {
    emoji: "🎠",
    key: "Carousel Horse",
  },
  {
    emoji: "🎡",
    key: "Ferris Wheel",
  },
  {
    emoji: "🎢",
    key: "Roller Coaster",
  },
  {
    emoji: "🚂",
    key: "Locomotive",
  },
  {
    emoji: "🚃",
    key: "Railway Car",
  },
  {
    emoji: "🚄",
    key: "High-Speed Train",
  },
  {
    emoji: "🚅",
    key: "Bullet Train",
  },
  {
    emoji: "🚆",
    key: "Train",
  },
  {
    emoji: "🚇",
    key: "Metro",
  },
  {
    emoji: "🚈",
    key: "Light Rail",
  },
  {
    emoji: "🚉",
    key: "Station",
  },
  {
    emoji: "🚊",
    key: "Tram",
  },
  {
    emoji: "🚝",
    key: "Monorail",
  },
  {
    emoji: "🚞",
    key: "Mountain Railway",
  },
  {
    emoji: "🚋",
    key: "Tram Car",
  },
  {
    emoji: "🚌",
    key: "Bus",
  },
  {
    emoji: "🚍",
    key: "Oncoming Bus",
  },
  {
    emoji: "🚎",
    key: "Trolleybus",
  },
  {
    emoji: "🚐",
    key: "Minibus",
  },
  {
    emoji: "🚑",
    key: "Ambulance",
  },
  {
    emoji: "🚒",
    key: "Fire Engine",
  },
  {
    emoji: "🚓",
    key: "Police Car",
  },
  {
    emoji: "🚔",
    key: "Oncoming Police Car",
  },
  {
    emoji: "🚕",
    key: "Taxi",
  },
  {
    emoji: "🚖",
    key: "Oncoming Taxi",
  },
  {
    emoji: "🚗",
    key: "Automobile",
  },
  {
    emoji: "🚘",
    key: "Oncoming Automobile",
  },
  {
    emoji: "🚚",
    key: "Delivery Truck",
  },
  {
    emoji: "🚛",
    key: "Articulated Lorry",
  },
  {
    emoji: "🚜",
    key: "Tractor",
  },
  {
    emoji: "🚲",
    key: "Bicycle",
  },
  {
    emoji: "🛴",
    key: "Kick Scooter",
  },
  {
    emoji: "🛵",
    key: "Motor Scooter",
  },
  {
    emoji: "🚏",
    key: "Bus Stop",
  },
  {
    emoji: "🛤",
    key: "Railway Track",
  },
  {
    emoji: "⛽",
    key: "Fuel Pump",
  },
  {
    emoji: "🚨",
    key: "Police Car Light",
  },
  {
    emoji: "🚥",
    key: "Horizontal Traffic Light",
  },
  {
    emoji: "🚦",
    key: "Vertical Traffic Light",
  },
  {
    emoji: "🚧",
    key: "Construction",
  },
  {
    emoji: "⚓",
    key: "Anchor",
  },
  {
    emoji: "⛵",
    key: "Sailboat",
  },
  {
    emoji: "🚤",
    key: "Speedboat",
  },
  {
    emoji: "🛳",
    key: "Passenger Ship",
  },
  {
    emoji: "⛴",
    key: "Ferry",
  },
  {
    emoji: "🛥",
    key: "Motor Boat",
  },
  {
    emoji: "🚢",
    key: "Ship",
  },
  {
    emoji: "✈",
    key: "Airplane",
  },
  {
    emoji: "🛩",
    key: "Small Airplane",
  },
  {
    emoji: "🛫",
    key: "Airplane Departure",
  },
  {
    emoji: "🛬",
    key: "Airplane Arrival",
  },
  {
    emoji: "💺",
    key: "Seat",
  },
  {
    emoji: "🚁",
    key: "Helicopter",
  },
  {
    emoji: "🚟",
    key: "Suspension Railway",
  },
  {
    emoji: "🚠",
    key: "Mountain Cableway",
  },
  {
    emoji: "🚡",
    key: "Aerial Tramway",
  },
  {
    emoji: "🛰",
    key: "Satellite",
  },
  {
    emoji: "🚀",
    key: "Rocket",
  },
  {
    emoji: "🛸",
    key: "Flying Saucer",
  },
  {
    emoji: "🌠",
    key: "Shooting Star",
  },
  {
    emoji: "⛱",
    key: "Umbrella on Ground",
  },
  {
    emoji: "🎆",
    key: "Fireworks",
  },
  {
    emoji: "🎇",
    key: "Sparkler",
  },
  {
    emoji: "🎑",
    key: "Moon Viewing Ceremony",
  },
  {
    emoji: "💴",
    key: "Yen Banknote",
  },
  {
    emoji: "💵",
    key: "Dollar Banknote",
  },
  {
    emoji: "💶",
    key: "Euro Banknote",
  },
  {
    emoji: "💷",
    key: "Pound Banknote",
  },
  {
    emoji: "🗿",
    key: "Moai",
  },
  {
    emoji: "🛂",
    key: "Passport Control",
  },
  {
    emoji: "🛃",
    key: "Customs",
  },
  {
    emoji: "🛄",
    key: "Baggage Claim",
  },
  {
    emoji: "🛅",
    key: "Left Luggage",
  },
  {
    emoji: "☠",
    key: "Skull and Crossbones",
  },
  {
    emoji: "🛀",
    key: "Person Taking Bath",
  },
  {
    emoji: "🛌",
    key: "Person in Bed",
  },
  {
    emoji: "💌",
    key: "Love Letter",
  },
  {
    emoji: "💣",
    key: "Bomb",
  },
  {
    emoji: "🕳",
    key: "Hole",
  },
  {
    emoji: "🛍",
    key: "Shopping Bags",
  },
  {
    emoji: "📿",
    key: "Prayer Beads",
  },
  {
    emoji: "💎",
    key: "Gem Stone",
  },
  {
    emoji: "🔪",
    key: "Kitchen Knife",
  },
  {
    emoji: "🏺",
    key: "Amphora",
  },
  {
    emoji: "🗺",
    key: "World Map",
  },
  {
    emoji: "💈",
    key: "Barber Pole",
  },
  {
    emoji: "🛢",
    key: "Oil Drum",
  },
  {
    emoji: "🛎",
    key: "Bellhop Bell",
  },
  {
    emoji: "⌛",
    key: "Hourglass Done",
  },
  {
    emoji: "⏳",
    key: "Hourglass Not Done",
  },
  {
    emoji: "⌚",
    key: "Watch",
  },
  {
    emoji: "⏰",
    key: "Alarm Clock",
  },
  {
    emoji: "⏱",
    key: "Stopwatch",
  },
  {
    emoji: "⏲",
    key: "Timer Clock",
  },
  {
    emoji: "🕰",
    key: "Mantelpiece Clock",
  },
  {
    emoji: "🌡",
    key: "Thermometer",
  },
  {
    emoji: "⛱",
    key: "Umbrella on Ground",
  },
  {
    emoji: "🎈",
    key: "Balloon",
  },
  {
    emoji: "🎉",
    key: "Party Popper",
  },
  {
    emoji: "🎊",
    key: "Confetti Ball",
  },
  {
    emoji: "🎎",
    key: "Japanese Dolls",
  },
  {
    emoji: "🎏",
    key: "Carp Streamer",
  },
  {
    emoji: "🎐",
    key: "Wind Chime",
  },
  {
    emoji: "🎀",
    key: "Ribbon",
  },
  {
    emoji: "🎁",
    key: "Wrapped Gift",
  },
  {
    emoji: "🔮",
    key: "Crystal Ball",
  },
  {
    emoji: "🕹",
    key: "Joystick",
  },
  {
    emoji: "🖼",
    key: "Framed Picture",
  },
  {
    emoji: "📯",
    key: "Postal Horn",
  },
  {
    emoji: "🎙",
    key: "Studio Microphone",
  },
  {
    emoji: "🎚",
    key: "Level Slider",
  },
  {
    emoji: "🎛",
    key: "Control Knobs",
  },
  {
    emoji: "📻",
    key: "Radio",
  },
  {
    emoji: "📱",
    key: "Mobile Phone",
  },
  {
    emoji: "📲",
    key: "Mobile Phone With Arrow",
  },
  {
    emoji: "☎",
    key: "Telephone",
  },
  {
    emoji: "📞",
    key: "Telephone Receiver",
  },
  {
    emoji: "📟",
    key: "Pager",
  },
  {
    emoji: "📠",
    key: "Fax Machine",
  },
  {
    emoji: "🔋",
    key: "Battery",
  },
  {
    emoji: "🔌",
    key: "Electric Plug",
  },
  {
    emoji: "💻",
    key: "Laptop Computer",
  },
  {
    emoji: "🖥",
    key: "Desktop Computer",
  },
  {
    emoji: "🖨",
    key: "Printer",
  },
  {
    emoji: "⌨",
    key: "Keyboard",
  },
  {
    emoji: "🖱",
    key: "Computer Mouse",
  },
  {
    emoji: "🖲",
    key: "Trackball",
  },
  {
    emoji: "💽",
    key: "Computer Disk",
  },
  {
    emoji: "💾",
    key: "Floppy Disk",
  },
  {
    emoji: "💿",
    key: "Optical Disk",
  },
  {
    emoji: "📀",
    key: "DVD",
  },
  {
    emoji: "🎥",
    key: "Movie Camera",
  },
  {
    emoji: "🎞",
    key: "Film Frames",
  },
  {
    emoji: "📽",
    key: "Film Projector",
  },
  {
    emoji: "📺",
    key: "Television",
  },
  {
    emoji: "📷",
    key: "Camera",
  },
  {
    emoji: "📸",
    key: "Camera With Flash",
  },
  {
    emoji: "📹",
    key: "Video Camera",
  },
  {
    emoji: "📼",
    key: "Videocassette",
  },
  {
    emoji: "🔍",
    key: "Magnifying Glass Tilted Left",
  },
  {
    emoji: "🔎",
    key: "Magnifying Glass Tilted Right",
  },
  {
    emoji: "🕯",
    key: "Candle",
  },
  {
    emoji: "💡",
    key: "Light Bulb",
  },
  {
    emoji: "🔦",
    key: "Flashlight",
  },
  {
    emoji: "🏮",
    key: "Red Paper Lantern",
  },
  {
    emoji: "📔",
    key: "Notebook With Decorative Cover",
  },
  {
    emoji: "📕",
    key: "Closed Book",
  },
  {
    emoji: "📖",
    key: "Open Book",
  },
  {
    emoji: "📗",
    key: "Green Book",
  },
  {
    emoji: "📘",
    key: "Blue Book",
  },
  {
    emoji: "📙",
    key: "Orange Book",
  },
  {
    emoji: "📚",
    key: "Books",
  },
  {
    emoji: "📓",
    key: "Notebook",
  },
  {
    emoji: "📃",
    key: "Page With Curl",
  },
  {
    emoji: "📜",
    key: "Scroll",
  },
  {
    emoji: "📄",
    key: "Page Facing Up",
  },
  {
    emoji: "📰",
    key: "Newspaper",
  },
  {
    emoji: "🗞",
    key: "Rolled-Up Newspaper",
  },
  {
    emoji: "📑",
    key: "Bookmark Tabs",
  },
  {
    emoji: "🔖",
    key: "Bookmark",
  },
  {
    emoji: "🏷",
    key: "Label",
  },
  {
    emoji: "💰",
    key: "Money Bag",
  },
  {
    emoji: "💴",
    key: "Yen Banknote",
  },
  {
    emoji: "💵",
    key: "Dollar Banknote",
  },
  {
    emoji: "💶",
    key: "Euro Banknote",
  },
  {
    emoji: "💷",
    key: "Pound Banknote",
  },
  {
    emoji: "💸",
    key: "Money With Wings",
  },
  {
    emoji: "💳",
    key: "Credit Card",
  },
  {
    emoji: "✉",
    key: "Envelope",
  },
  {
    emoji: "📧",
    key: "E-Mail",
  },
  {
    emoji: "📨",
    key: "Incoming Envelope",
  },
  {
    emoji: "📩",
    key: "Envelope With Arrow",
  },
  {
    emoji: "📤",
    key: "Outbox Tray",
  },
  {
    emoji: "📥",
    key: "Inbox Tray",
  },
  {
    emoji: "📦",
    key: "Package",
  },
  {
    emoji: "📫",
    key: "Closed Mailbox With Raised Flag",
  },
  {
    emoji: "📪",
    key: "Closed Mailbox With Lowered Flag",
  },
  {
    emoji: "📬",
    key: "Open Mailbox With Raised Flag",
  },
  {
    emoji: "📭",
    key: "Open Mailbox With Lowered Flag",
  },
  {
    emoji: "📮",
    key: "Postbox",
  },
  {
    emoji: "🗳",
    key: "Ballot Box With Ballot",
  },
  {
    emoji: "✏",
    key: "Pencil",
  },
  {
    emoji: "✒",
    key: "Black Nib",
  },
  {
    emoji: "🖋",
    key: "Fountain Pen",
  },
  {
    emoji: "🖊",
    key: "Pen",
  },
  {
    emoji: "🖌",
    key: "Paintbrush",
  },
  {
    emoji: "🖍",
    key: "Crayon",
  },
  {
    emoji: "📝",
    key: "Memo",
  },
  {
    emoji: "📁",
    key: "File Folder",
  },
  {
    emoji: "📂",
    key: "Open File Folder",
  },
  {
    emoji: "🗂",
    key: "Card Index Dividers",
  },
  {
    emoji: "📅",
    key: "Calendar",
  },
  {
    emoji: "📆",
    key: "Tear-Off Calendar",
  },
  {
    emoji: "🗒",
    key: "Spiral Notepad",
  },
  {
    emoji: "🗓",
    key: "Spiral Calendar",
  },
  {
    emoji: "📇",
    key: "Card Index",
  },
  {
    emoji: "📈",
    key: "Chart Increasing",
  },
  {
    emoji: "📉",
    key: "Chart Decreasing",
  },
  {
    emoji: "📊",
    key: "Bar Chart",
  },
  {
    emoji: "📋",
    key: "Clipboard",
  },
  {
    emoji: "📌",
    key: "Pushpin",
  },
  {
    emoji: "📍",
    key: "Round Pushpin",
  },
  {
    emoji: "📎",
    key: "Paperclip",
  },
  {
    emoji: "🖇",
    key: "Linked Paperclips",
  },
  {
    emoji: "📏",
    key: "Straight Ruler",
  },
  {
    emoji: "📐",
    key: "Triangular Ruler",
  },
  {
    emoji: "✂",
    key: "Scissors",
  },
  {
    emoji: "🗃",
    key: "Card File Box",
  },
  {
    emoji: "🗄",
    key: "File Cabinet",
  },
  {
    emoji: "🗑",
    key: "Wastebasket",
  },
  {
    emoji: "🔒",
    key: "Locked",
  },
  {
    emoji: "🔓",
    key: "Unlocked",
  },
  {
    emoji: "🔏",
    key: "Locked With Pen",
  },
  {
    emoji: "🔐",
    key: "Locked With Key",
  },
  {
    emoji: "🔑",
    key: "Key",
  },
  {
    emoji: "🗝",
    key: "Old Key",
  },
  {
    emoji: "🔨",
    key: "Hammer",
  },
  {
    emoji: "⛏",
    key: "Pick",
  },
  {
    emoji: "⚒",
    key: "Hammer and Pick",
  },
  {
    emoji: "🛠",
    key: "Hammer and Wrench",
  },
  {
    emoji: "🗡",
    key: "Dagger",
  },
  {
    emoji: "⚔",
    key: "Crossed Swords",
  },
  {
    emoji: "🔫",
    key: "Pistol",
  },
  {
    emoji: "🛡",
    key: "Shield",
  },
  {
    emoji: "🔧",
    key: "Wrench",
  },
  {
    emoji: "🔩",
    key: "Nut and Bolt",
  },
  {
    emoji: "⚙",
    key: "Gear",
  },
  {
    emoji: "🗜",
    key: "Clamp",
  },
  {
    emoji: "⚖",
    key: "Balance Scale",
  },
  {
    emoji: "🔗",
    key: "Link",
  },
  {
    emoji: "⛓",
    key: "Chains",
  },
  {
    emoji: "⚗",
    key: "Alembic",
  },
  {
    emoji: "🔬",
    key: "Microscope",
  },
  {
    emoji: "🔭",
    key: "Telescope",
  },
  {
    emoji: "📡",
    key: "Satellite Antenna",
  },
  {
    emoji: "💉",
    key: "Syringe",
  },
  {
    emoji: "💊",
    key: "Pill",
  },
  {
    emoji: "🚪",
    key: "Door",
  },
  {
    emoji: "🛏",
    key: "Bed",
  },
  {
    emoji: "🛋",
    key: "Couch and Lamp",
  },
  {
    emoji: "🚽",
    key: "Toilet",
  },
  {
    emoji: "🚿",
    key: "Shower",
  },
  {
    emoji: "🛁",
    key: "Bathtub",
  },
  {
    emoji: "🚬",
    key: "Cigarette",
  },
  {
    emoji: "⚰",
    key: "Coffin",
  },
  {
    emoji: "⚱",
    key: "Funeral Urn",
  },
  {
    emoji: "🗿",
    key: "Moai",
  },
  {
    emoji: "🚰",
    key: "Potable Water",
  },
  {
    emoji: "👁️‍🗨️",
    key: "Eye in Speech Bubble",
  },
  {
    emoji: "💘",
    key: "Heart With Arrow",
  },
  {
    emoji: "❤",
    key: "Red Heart",
  },
  {
    emoji: "💓",
    key: "Beating Heart",
  },
  {
    emoji: "💔",
    key: "Broken Heart",
  },
  {
    emoji: "💕",
    key: "Two Hearts",
  },
  {
    emoji: "💖",
    key: "Sparkling Heart",
  },
  {
    emoji: "💗",
    key: "Growing Heart",
  },
  {
    emoji: "💙",
    key: "Blue Heart",
  },
  {
    emoji: "💚",
    key: "Green Heart",
  },
  {
    emoji: "💛",
    key: "Yellow Heart",
  },
  {
    emoji: "🧡",
    key: "Orange Heart",
  },
  {
    emoji: "💜",
    key: "Purple Heart",
  },
  {
    emoji: "🖤",
    key: "Black Heart",
  },
  {
    emoji: "💝",
    key: "Heart With Ribbon",
  },
  {
    emoji: "💞",
    key: "Revolving Hearts",
  },
  {
    emoji: "💟",
    key: "Heart Decoration",
  },
  {
    emoji: "❣",
    key: "Heavy Heart Exclamation",
  },
  {
    emoji: "💤",
    key: "Zzz",
  },
  {
    emoji: "💢",
    key: "Anger Symbol",
  },
  {
    emoji: "💬",
    key: "Speech Balloon",
  },
  {
    emoji: "🗯",
    key: "Right Anger Bubble",
  },
  {
    emoji: "💭",
    key: "Thought Balloon",
  },
  {
    emoji: "💮",
    key: "White Flower",
  },
  {
    emoji: "♨",
    key: "Hot Springs",
  },
  {
    emoji: "💈",
    key: "Barber Pole",
  },
  {
    emoji: "🛑",
    key: "Stop Sign",
  },
  {
    emoji: "🕛",
    key: "Twelve O’clock",
  },
  {
    emoji: "🕧",
    key: "Twelve-Thirty",
  },
  {
    emoji: "🕐",
    key: "One O’clock",
  },
  {
    emoji: "🕜",
    key: "One-Thirty",
  },
  {
    emoji: "🕑",
    key: "Two O’clock",
  },
  {
    emoji: "🕝",
    key: "Two-Thirty",
  },
  {
    emoji: "🕒",
    key: "Three O’clock",
  },
  {
    emoji: "🕞",
    key: "Three-Thirty",
  },
  {
    emoji: "🕓",
    key: "Four O’clock",
  },
  {
    emoji: "🕟",
    key: "Four-Thirty",
  },
  {
    emoji: "🕔",
    key: "Five O’clock",
  },
  {
    emoji: "🕠",
    key: "Five-Thirty",
  },
  {
    emoji: "🕕",
    key: "Six O’clock",
  },
  {
    emoji: "🕡",
    key: "Six-Thirty",
  },
  {
    emoji: "🕖",
    key: "Seven O’clock",
  },
  {
    emoji: "🕢",
    key: "Seven-Thirty",
  },
  {
    emoji: "🕗",
    key: "Eight O’clock",
  },
  {
    emoji: "🕣",
    key: "Eight-Thirty",
  },
  {
    emoji: "🕘",
    key: "Nine O’clock",
  },
  {
    emoji: "🕤",
    key: "Nine-Thirty",
  },
  {
    emoji: "🕙",
    key: "Ten O’clock",
  },
  {
    emoji: "🕥",
    key: "Ten-Thirty",
  },
  {
    emoji: "🕚",
    key: "Eleven O’clock",
  },
  {
    emoji: "🕦",
    key: "Eleven-Thirty",
  },
  {
    emoji: "🌀",
    key: "Cyclone",
  },
  {
    emoji: "♠",
    key: "Spade Suit",
  },
  {
    emoji: "♥",
    key: "Heart Suit",
  },
  {
    emoji: "♦",
    key: "Diamond Suit",
  },
  {
    emoji: "♣",
    key: "Club Suit",
  },
  {
    emoji: "🃏",
    key: "Joker",
  },
  {
    emoji: "🀄",
    key: "Mahjong Red Dragon",
  },
  {
    emoji: "🎴",
    key: "Flower Playing Cards",
  },
  {
    emoji: "🔇",
    key: "Muted Speaker",
  },
  {
    emoji: "🔈",
    key: "Speaker Low Volume",
  },
  {
    emoji: "🔉",
    key: "Speaker Medium Volume",
  },
  {
    emoji: "🔊",
    key: "Speaker High Volume",
  },
  {
    emoji: "📢",
    key: "Loudspeaker",
  },
  {
    emoji: "📣",
    key: "Megaphone",
  },
  {
    emoji: "📯",
    key: "Postal Horn",
  },
  {
    emoji: "🔔",
    key: "Bell",
  },
  {
    emoji: "🔕",
    key: "Bell With Slash",
  },
  {
    emoji: "🎵",
    key: "Musical Note",
  },
  {
    emoji: "🎶",
    key: "Musical Notes",
  },
  {
    emoji: "🏧",
    key: "Atm Sign",
  },
  {
    emoji: "🚮",
    key: "Litter in Bin Sign",
  },
  {
    emoji: "🚰",
    key: "Potable Water",
  },
  {
    emoji: "♿",
    key: "Wheelchair Symbol",
  },
  {
    emoji: "🚹",
    key: "Men’s Room",
  },
  {
    emoji: "🚺",
    key: "Women’s Room",
  },
  {
    emoji: "🚻",
    key: "Restroom",
  },
  {
    emoji: "🚼",
    key: "Baby Symbol",
  },
  {
    emoji: "🚾",
    key: "Water Closet",
  },
  {
    emoji: "⚠",
    key: "Warning",
  },
  {
    emoji: "🚸",
    key: "Children Crossing",
  },
  {
    emoji: "⛔",
    key: "No Entry",
  },
  {
    emoji: "🚫",
    key: "Prohibited",
  },
  {
    emoji: "🚳",
    key: "No Bicycles",
  },
  {
    emoji: "🚭",
    key: "No Smoking",
  },
  {
    emoji: "🚯",
    key: "No Littering",
  },
  {
    emoji: "🚱",
    key: "Non-Potable Water",
  },
  {
    emoji: "🚷",
    key: "No Pedestrians",
  },
  {
    emoji: "🔞",
    key: "No One Under Eighteen",
  },
  {
    emoji: "☢",
    key: "Radioactive",
  },
  {
    emoji: "☣",
    key: "Biohazard",
  },
  {
    emoji: "⬆",
    key: "Up Arrow",
  },
  {
    emoji: "↗",
    key: "Up-Right Arrow",
  },
  {
    emoji: "➡",
    key: "Right Arrow",
  },
  {
    emoji: "↘",
    key: "Down-Right Arrow",
  },
  {
    emoji: "⬇",
    key: "Down Arrow",
  },
  {
    emoji: "↙",
    key: "Down-Left Arrow",
  },
  {
    emoji: "⬅",
    key: "Left Arrow",
  },
  {
    emoji: "↖",
    key: "Up-Left Arrow",
  },
  {
    emoji: "↕",
    key: "Up-Down Arrow",
  },
  {
    emoji: "↔",
    key: "Left-Right Arrow",
  },
  {
    emoji: "↩",
    key: "Right Arrow Curving Left",
  },
  {
    emoji: "↪",
    key: "Left Arrow Curving Right",
  },
  {
    emoji: "⤴",
    key: "Right Arrow Curving Up",
  },
  {
    emoji: "⤵",
    key: "Right Arrow Curving Down",
  },
  {
    emoji: "🔃",
    key: "Clockwise Vertical Arrows",
  },
  {
    emoji: "🔄",
    key: "Counterclockwise Arrows Button",
  },
  {
    emoji: "🔙",
    key: "Back Arrow",
  },
  {
    emoji: "🔚",
    key: "End Arrow",
  },
  {
    emoji: "🔛",
    key: "On! Arrow",
  },
  {
    emoji: "🔜",
    key: "Soon Arrow",
  },
  {
    emoji: "🔝",
    key: "Top Arrow",
  },
  {
    emoji: "🛐",
    key: "Place of Worship",
  },
  {
    emoji: "⚛",
    key: "Atom Symbol",
  },
  {
    emoji: "🕉",
    key: "Om",
  },
  {
    emoji: "✡",
    key: "Star of David",
  },
  {
    emoji: "☸",
    key: "Wheel of Dharma",
  },
  {
    emoji: "☯",
    key: "Yin Yang",
  },
  {
    emoji: "✝",
    key: "Latin Cross",
  },
  {
    emoji: "☦",
    key: "Orthodox Cross",
  },
  {
    emoji: "☪",
    key: "Star and Crescent",
  },
  {
    emoji: "☮",
    key: "Peace Symbol",
  },
  {
    emoji: "🕎",
    key: "Menorah",
  },
  {
    emoji: "🔯",
    key: "Dotted Six-Pointed Star",
  },
  {
    emoji: "♈",
    key: "Aries",
  },
  {
    emoji: "♉",
    key: "Taurus",
  },
  {
    emoji: "♊",
    key: "Gemini",
  },
  {
    emoji: "♋",
    key: "Cancer",
  },
  {
    emoji: "♌",
    key: "Leo",
  },
  {
    emoji: "♍",
    key: "Virgo",
  },
  {
    emoji: "♎",
    key: "Libra",
  },
  {
    emoji: "♏",
    key: "Scorpio",
  },
  {
    emoji: "♐",
    key: "Sagittarius",
  },
  {
    emoji: "♑",
    key: "Capricorn",
  },
  {
    emoji: "♒",
    key: "Aquarius",
  },
  {
    emoji: "♓",
    key: "Pisces",
  },
  {
    emoji: "⛎",
    key: "Ophiuchus",
  },
  {
    emoji: "🔀",
    key: "Shuffle Tracks Button",
  },
  {
    emoji: "🔁",
    key: "Repeat Button",
  },
  {
    emoji: "🔂",
    key: "Repeat Single Button",
  },
  {
    emoji: "▶",
    key: "Play Button",
  },
  {
    emoji: "⏩",
    key: "Fast-Forward Button",
  },
  {
    emoji: "◀",
    key: "Reverse Button",
  },
  {
    emoji: "⏪",
    key: "Fast Reverse Button",
  },
  {
    emoji: "🔼",
    key: "Upwards Button",
  },
  {
    emoji: "⏫",
    key: "Fast Up Button",
  },
  {
    emoji: "🔽",
    key: "Downwards Button",
  },
  {
    emoji: "⏬",
    key: "Fast Down Button",
  },
  {
    emoji: "⏹",
    key: "Stop Button",
  },
  {
    emoji: "⏏",
    key: "Eject Button",
  },
  {
    emoji: "🎦",
    key: "Cinema",
  },
  {
    emoji: "🔅",
    key: "Dim Button",
  },
  {
    emoji: "🔆",
    key: "Bright Button",
  },
  {
    emoji: "📶",
    key: "Antenna Bars",
  },
  {
    emoji: "📳",
    key: "Vibration Mode",
  },
  {
    emoji: "📴",
    key: "Mobile Phone Off",
  },
  {
    emoji: "♻",
    key: "Recycling Symbol",
  },
  {
    emoji: "🔱",
    key: "Trident Emblem",
  },
  {
    emoji: "📛",
    key: "Name Badge",
  },
  {
    emoji: "🔰",
    key: "Japanese Symbol for Beginner",
  },
  {
    emoji: "⭕",
    key: "Heavy Large Circle",
  },
  {
    emoji: "✅",
    key: "White Heavy Check Mark",
  },
  {
    emoji: "☑",
    key: "Ballot Box With Check",
  },
  {
    emoji: "✔",
    key: "Heavy Check Mark",
  },
  {
    emoji: "✖",
    key: "Heavy Multiplication X",
  },
  {
    emoji: "❌",
    key: "Cross Mark",
  },
  {
    emoji: "❎",
    key: "Cross Mark Button",
  },
  {
    emoji: "➕",
    key: "Heavy Plus Sign",
  },
  {
    emoji: "➖",
    key: "Heavy Minus Sign",
  },
  {
    emoji: "➗",
    key: "Heavy Division Sign",
  },
  {
    emoji: "➰",
    key: "Curly Loop",
  },
  {
    emoji: "➿",
    key: "Double Curly Loop",
  },
  {
    emoji: "〽",
    key: "Part Alternation Mark",
  },
  {
    emoji: "✳",
    key: "Eight-Spoked Asterisk",
  },
  {
    emoji: "✴",
    key: "Eight-Pointed Star",
  },
  {
    emoji: "❇",
    key: "Sparkle",
  },
  {
    emoji: "‼",
    key: "Double Exclamation Mark",
  },
  {
    emoji: "⁉",
    key: "Exclamation Question Mark",
  },
  {
    emoji: "❓",
    key: "Question Mark",
  },
  {
    emoji: "❔",
    key: "White Question Mark",
  },
  {
    emoji: "❕",
    key: "White Exclamation Mark",
  },
  {
    emoji: "❗",
    key: "Exclamation Mark",
  },
  {
    emoji: "©",
    key: "Copyright",
  },
  {
    emoji: "®",
    key: "Registered",
  },
  {
    emoji: "™",
    key: "Trade Mark",
  },
  {
    emoji: "#️⃣",
    key: "Keycap Number Sign",
  },
  {
    emoji: "0️⃣",
    key: "Keycap Digit Zero",
  },
  {
    emoji: "1️⃣",
    key: "Keycap Digit One",
  },
  {
    emoji: "2️⃣",
    key: "Keycap Digit Two",
  },
  {
    emoji: "3️⃣",
    key: "Keycap Digit Three",
  },
  {
    emoji: "4️⃣",
    key: "Keycap Digit Four",
  },
  {
    emoji: "5️⃣",
    key: "Keycap Digit Five",
  },
  {
    emoji: "6️⃣",
    key: "Keycap Digit Six",
  },
  {
    emoji: "7️⃣",
    key: "Keycap Digit Seven",
  },
  {
    emoji: "8️⃣",
    key: "Keycap Digit Eight",
  },
  {
    emoji: "9️⃣",
    key: "Keycap Digit Nine",
  },
  {
    emoji: "🔟",
    key: "Keycap 10",
  },
  {
    emoji: "💯",
    key: "Hundred Points",
  },
  {
    emoji: "🔠",
    key: "Input Latin Uppercase",
  },
  {
    emoji: "🔡",
    key: "Input Latin Lowercase",
  },
  {
    emoji: "🔢",
    key: "Input Numbers",
  },
  {
    emoji: "🔣",
    key: "Input Symbols",
  },
  {
    emoji: "🔤",
    key: "Input Latin Letters",
  },
  {
    emoji: "🅰",
    key: "A Button (blood Type)",
  },
  {
    emoji: "🆎",
    key: "Ab Button (blood Type)",
  },
  {
    emoji: "🅱",
    key: "B Button (blood Type)",
  },
  {
    emoji: "🆑",
    key: "CL Button",
  },
  {
    emoji: "🆒",
    key: "Cool Button",
  },
  {
    emoji: "🆓",
    key: "Free Button",
  },
  {
    emoji: "ℹ",
    key: "Information",
  },
  {
    emoji: "🆔",
    key: "ID Button",
  },
  {
    emoji: "Ⓜ",
    key: "Circled M",
  },
  {
    emoji: "🆕",
    key: "New Button",
  },
  {
    emoji: "🆖",
    key: "NG Button",
  },
  {
    emoji: "🅾",
    key: "O Button (blood Type)",
  },
  {
    emoji: "🆗",
    key: "OK Button",
  },
  {
    emoji: "🅿",
    key: "P Button",
  },
  {
    emoji: "🆘",
    key: "SOS Button",
  },
  {
    emoji: "🆙",
    key: "Up! Button",
  },
  {
    emoji: "🆚",
    key: "Vs Button",
  },
  {
    emoji: "🈁",
    key: "Japanese “here” Button",
  },
  {
    emoji: "🈂",
    key: "Japanese “service Charge” Button",
  },
  {
    emoji: "🈷",
    key: "Japanese “monthly Amount” Button",
  },
  {
    emoji: "🈶",
    key: "Japanese “not Free of Charge” Button",
  },
  {
    emoji: "🈯",
    key: "Japanese “reserved” Button",
  },
  {
    emoji: "🉐",
    key: "Japanese “bargain” Button",
  },
  {
    emoji: "🈹",
    key: "Japanese “discount” Button",
  },
  {
    emoji: "🈚",
    key: "Japanese “free of Charge” Button",
  },
  {
    emoji: "🈲",
    key: "Japanese “prohibited” Button",
  },
  {
    emoji: "🉑",
    key: "Japanese “acceptable” Button",
  },
  {
    emoji: "🈸",
    key: "Japanese “application” Button",
  },
  {
    emoji: "🈴",
    key: "Japanese “passing Grade” Button",
  },
  {
    emoji: "🈳",
    key: "Japanese “vacancy” Button",
  },
  {
    emoji: "㊗",
    key: "Japanese “congratulations” Button",
  },
  {
    emoji: "㊙",
    key: "Japanese “secret” Button",
  },
  {
    emoji: "🈺",
    key: "Japanese “open for Business” Button",
  },
  {
    emoji: "🈵",
    key: "Japanese “no Vacancy” Button",
  },
  {
    emoji: "▪",
    key: "Black Small Square",
  },
  {
    emoji: "▫",
    key: "White Small Square",
  },
  {
    emoji: "◻",
    key: "White Medium Square",
  },
  {
    emoji: "◼",
    key: "Black Medium Square",
  },
  {
    emoji: "◽",
    key: "White Medium-Small Square",
  },
  {
    emoji: "◾",
    key: "Black Medium-Small Square",
  },
  {
    emoji: "⬛",
    key: "Black Large Square",
  },
  {
    emoji: "⬜",
    key: "White Large Square",
  },
  {
    emoji: "🔶",
    key: "Large Orange Diamond",
  },
  {
    emoji: "🔷",
    key: "Large Blue Diamond",
  },
  {
    emoji: "🔸",
    key: "Small Orange Diamond",
  },
  {
    emoji: "🔹",
    key: "Small Blue Diamond",
  },
  {
    emoji: "🔺",
    key: "Red Triangle Pointed Up",
  },
  {
    emoji: "🔻",
    key: "Red Triangle Pointed Down",
  },
  {
    emoji: "💠",
    key: "Diamond With a Dot",
  },
  {
    emoji: "🔲",
    key: "Black Square Button",
  },
  {
    emoji: "🔳",
    key: "White Square Button",
  },
  {
    emoji: "⚪",
    key: "White Circle",
  },
  {
    emoji: "⚫",
    key: "Black Circle",
  },
  {
    emoji: "🔴",
    key: "Red Circle",
  },
  {
    emoji: "🔵",
    key: "Blue Circle",
  },
  {
    emoji: "🏁",
    key: "Chequered Flag",
  },
  {
    emoji: "🚩",
    key: "Triangular Flag",
  },
  {
    emoji: "🎌",
    key: "Crossed Flags",
  },
  {
    emoji: "🏴",
    key: "Black Flag",
  },
  {
    emoji: "🏳",
    key: "White Flag",
  },
  {
    emoji: "🏳️‍🌈",
    key: "Rainbow Flag",
  },
  {
    emoji: "🏴‍☠️",
    key: "Pirate Flag",
  },
  {
    emoji: "🇦🇨",
    key: "Ascension Island",
  },
  {
    emoji: "🇦🇩",
    key: "Andorra",
  },
  {
    emoji: "🇦🇪",
    key: "United Arab Emirates",
  },
  {
    emoji: "🇦🇫",
    key: "Afghanistan",
  },
  {
    emoji: "🇦🇬",
    key: "Antigua & Barbuda",
  },
  {
    emoji: "🇦🇮",
    key: "Anguilla",
  },
  {
    emoji: "🇦🇱",
    key: "Albania",
  },
  {
    emoji: "🇦🇲",
    key: "Armenia",
  },
  {
    emoji: "🇦🇴",
    key: "Angola",
  },
  {
    emoji: "🇦🇶",
    key: "Antarctica",
  },
  {
    emoji: "🇦🇷",
    key: "Argentina",
  },
  {
    emoji: "🇦🇸",
    key: "American Samoa",
  },
  {
    emoji: "🇦🇹",
    key: "Austria",
  },
  {
    emoji: "🇦🇺",
    key: "Australia",
  },
  {
    emoji: "🇦🇼",
    key: "Aruba",
  },
  {
    emoji: "🇦🇽",
    key: "Åland Islands",
  },
  {
    emoji: "🇦🇿",
    key: "Azerbaijan",
  },
  {
    emoji: "🇧🇦",
    key: "Bosnia & Herzegovina",
  },
  {
    emoji: "🇧🇧",
    key: "Barbados",
  },
  {
    emoji: "🇧🇩",
    key: "Bangladesh",
  },
  {
    emoji: "🇧🇪",
    key: "Belgium",
  },
  {
    emoji: "🇧🇫",
    key: "Burkina Faso",
  },
  {
    emoji: "🇧🇬",
    key: "Bulgaria",
  },
  {
    emoji: "🇧🇭",
    key: "Bahrain",
  },
  {
    emoji: "🇧🇮",
    key: "Burundi",
  },
  {
    emoji: "🇧🇯",
    key: "Benin",
  },
  {
    emoji: "🇧🇱",
    key: "St. Barthélemy",
  },
  {
    emoji: "🇧🇲",
    key: "Bermuda",
  },
  {
    emoji: "🇧🇳",
    key: "Brunei",
  },
  {
    emoji: "🇧🇴",
    key: "Bolivia",
  },
  {
    emoji: "🇧🇶",
    key: "Caribbean Netherlands",
  },
  {
    emoji: "🇧🇷",
    key: "Brazil",
  },
  {
    emoji: "🇧🇸",
    key: "Bahamas",
  },
  {
    emoji: "🇧🇹",
    key: "Bhutan",
  },
  {
    emoji: "🇧🇻",
    key: "Bouvet Island",
  },
  {
    emoji: "🇧🇼",
    key: "Botswana",
  },
  {
    emoji: "🇧🇾",
    key: "Belarus",
  },
  {
    emoji: "🇧🇿",
    key: "Belize",
  },
  {
    emoji: "🇨🇦",
    key: "Canada",
  },
  {
    emoji: "🇨🇨",
    key: "Cocos (Keeling) Islands",
  },
  {
    emoji: "🇨🇩",
    key: "Congo - Kinshasa",
  },
  {
    emoji: "🇨🇫",
    key: "Central African Republic",
  },
  {
    emoji: "🇨🇬",
    key: "Congo - Brazzaville",
  },
  {
    emoji: "🇨🇭",
    key: "Switzerland",
  },
  {
    emoji: "🇨🇮",
    key: "Côte D’Ivoire",
  },
  {
    emoji: "🇨🇰",
    key: "Cook Islands",
  },
  {
    emoji: "🇨🇱",
    key: "Chile",
  },
  {
    emoji: "🇨🇲",
    key: "Cameroon",
  },
  {
    emoji: "🇨🇳",
    key: "China",
  },
  {
    emoji: "🇨🇴",
    key: "Colombia",
  },
  {
    emoji: "🇨🇵",
    key: "Clipperton Island",
  },
  {
    emoji: "🇨🇷",
    key: "Costa Rica",
  },
  {
    emoji: "🇨🇺",
    key: "Cuba",
  },
  {
    emoji: "🇨🇻",
    key: "Cape Verde",
  },
  {
    emoji: "🇨🇼",
    key: "Curaçao",
  },
  {
    emoji: "🇨🇽",
    key: "Christmas Island",
  },
  {
    emoji: "🇨🇾",
    key: "Cyprus",
  },
  {
    emoji: "🇨🇿",
    key: "Czechia",
  },
  {
    emoji: "🇩🇪",
    key: "Germany",
  },
  {
    emoji: "🇩🇬",
    key: "Diego Garcia",
  },
  {
    emoji: "🇩🇯",
    key: "Djibouti",
  },
  {
    emoji: "🇩🇰",
    key: "Denmark",
  },
  {
    emoji: "🇩🇲",
    key: "Dominica",
  },
  {
    emoji: "🇩🇴",
    key: "Dominican Republic",
  },
  {
    emoji: "🇩🇿",
    key: "Algeria",
  },
  {
    emoji: "🇪🇦",
    key: "Ceuta & Melilla",
  },
  {
    emoji: "🇪🇨",
    key: "Ecuador",
  },
  {
    emoji: "🇪🇪",
    key: "Estonia",
  },
  {
    emoji: "🇪🇬",
    key: "Egypt",
  },
  {
    emoji: "🇪🇭",
    key: "Western Sahara",
  },
  {
    emoji: "🇪🇷",
    key: "Eritrea",
  },
  {
    emoji: "🇪🇸",
    key: "Spain",
  },
  {
    emoji: "🇪🇹",
    key: "Ethiopia",
  },
  {
    emoji: "🇪🇺",
    key: "European Union",
  },
  {
    emoji: "🇫🇮",
    key: "Finland",
  },
  {
    emoji: "🇫🇯",
    key: "Fiji",
  },
  {
    emoji: "🇫🇰",
    key: "Falkland Islands",
  },
  {
    emoji: "🇫🇲",
    key: "Micronesia",
  },
  {
    emoji: "🇫🇴",
    key: "Faroe Islands",
  },
  {
    emoji: "🇫🇷",
    key: "France",
  },
  {
    emoji: "🇬🇦",
    key: "Gabon",
  },
  {
    emoji: "🇬🇧",
    key: "United Kingdom",
  },
  {
    emoji: "🇬🇩",
    key: "Grenada",
  },
  {
    emoji: "🇬🇪",
    key: "Georgia",
  },
  {
    emoji: "🇬🇫",
    key: "French Guiana",
  },
  {
    emoji: "🇬🇬",
    key: "Guernsey",
  },
  {
    emoji: "🇬🇭",
    key: "Ghana",
  },
  {
    emoji: "🇬🇮",
    key: "Gibraltar",
  },
  {
    emoji: "🇬🇱",
    key: "Greenland",
  },
  {
    emoji: "🇬🇲",
    key: "Gambia",
  },
  {
    emoji: "🇬🇳",
    key: "Guinea",
  },
  {
    emoji: "🇬🇵",
    key: "Guadeloupe",
  },
  {
    emoji: "🇬🇶",
    key: "Equatorial Guinea",
  },
  {
    emoji: "🇬🇷",
    key: "Greece",
  },
  {
    emoji: "🇬🇸",
    key: "South Georgia & South Sandwich Islands",
  },
  {
    emoji: "🇬🇹",
    key: "Guatemala",
  },
  {
    emoji: "🇬🇺",
    key: "Guam",
  },
  {
    emoji: "🇬🇼",
    key: "Guinea-Bissau",
  },
  {
    emoji: "🇬🇾",
    key: "Guyana",
  },
  {
    emoji: "🇭🇰",
    key: "Hong Kong SAR China",
  },
  {
    emoji: "🇭🇲",
    key: "Heard & McDonald Islands",
  },
  {
    emoji: "🇭🇳",
    key: "Honduras",
  },
  {
    emoji: "🇭🇷",
    key: "Croatia",
  },
  {
    emoji: "🇭🇹",
    key: "Haiti",
  },
  {
    emoji: "🇭🇺",
    key: "Hungary",
  },
  {
    emoji: "🇮🇨",
    key: "Canary Islands",
  },
  {
    emoji: "🇮🇩",
    key: "Indonesia",
  },
  {
    emoji: "🇮🇪",
    key: "Ireland",
  },
  {
    emoji: "🇮🇱",
    key: "Israel",
  },
  {
    emoji: "🇮🇲",
    key: "Isle of Man",
  },
  {
    emoji: "🇮🇳",
    key: "India",
  },
  {
    emoji: "🇮🇴",
    key: "British Indian Ocean Territory",
  },
  {
    emoji: "🇮🇶",
    key: "Iraq",
  },
  {
    emoji: "🇮🇷",
    key: "Iran",
  },
  {
    emoji: "🇮🇸",
    key: "Iceland",
  },
  {
    emoji: "🇮🇹",
    key: "Italy",
  },
  {
    emoji: "🇯🇪",
    key: "Jersey",
  },
  {
    emoji: "🇯🇲",
    key: "Jamaica",
  },
  {
    emoji: "🇯🇴",
    key: "Jordan",
  },
  {
    emoji: "🇯🇵",
    key: "Japan",
  },
  {
    emoji: "🇰🇪",
    key: "Kenya",
  },
  {
    emoji: "🇰🇬",
    key: "Kyrgyzstan",
  },
  {
    emoji: "🇰🇭",
    key: "Cambodia",
  },
  {
    emoji: "🇰🇮",
    key: "Kiribati",
  },
  {
    emoji: "🇰🇲",
    key: "Comoros",
  },
  {
    emoji: "🇰🇳",
    key: "St. Kitts & Nevis",
  },
  {
    emoji: "🇰🇵",
    key: "North Korea",
  },
  {
    emoji: "🇰🇷",
    key: "South Korea",
  },
  {
    emoji: "🇰🇼",
    key: "Kuwait",
  },
  {
    emoji: "🇰🇾",
    key: "Cayman Islands",
  },
  {
    emoji: "🇰🇿",
    key: "Kazakhstan",
  },
  {
    emoji: "🇱🇦",
    key: "Laos",
  },
  {
    emoji: "🇱🇧",
    key: "Lebanon",
  },
  {
    emoji: "🇱🇨",
    key: "St. Lucia",
  },
  {
    emoji: "🇱🇮",
    key: "Liechtenstein",
  },
  {
    emoji: "🇱🇰",
    key: "Sri Lanka",
  },
  {
    emoji: "🇱🇷",
    key: "Liberia",
  },
  {
    emoji: "🇱🇸",
    key: "Lesotho",
  },
  {
    emoji: "🇱🇹",
    key: "Lithuania",
  },
  {
    emoji: "🇱🇺",
    key: "Luxembourg",
  },
  {
    emoji: "🇱🇻",
    key: "Latvia",
  },
  {
    emoji: "🇱🇾",
    key: "Libya",
  },
  {
    emoji: "🇲🇦",
    key: "Morocco",
  },
  {
    emoji: "🇲🇨",
    key: "Monaco",
  },
  {
    emoji: "🇲🇩",
    key: "Moldova",
  },
  {
    emoji: "🇲🇪",
    key: "Montenegro",
  },
  {
    emoji: "🇲🇫",
    key: "St. Martin",
  },
  {
    emoji: "🇲🇬",
    key: "Madagascar",
  },
  {
    emoji: "🇲🇭",
    key: "Marshall Islands",
  },
  {
    emoji: "🇲🇰",
    key: "Macedonia",
  },
  {
    emoji: "🇲🇱",
    key: "Mali",
  },
  {
    emoji: "🇲🇲",
    key: "Myanmar (Burma)",
  },
  {
    emoji: "🇲🇳",
    key: "Mongolia",
  },
  {
    emoji: "🇲🇴",
    key: "Macau SAR China",
  },
  {
    emoji: "🇲🇵",
    key: "Northern Mariana Islands",
  },
  {
    emoji: "🇲🇶",
    key: "Martinique",
  },
  {
    emoji: "🇲🇷",
    key: "Mauritania",
  },
  {
    emoji: "🇲🇸",
    key: "Montserrat",
  },
  {
    emoji: "🇲🇹",
    key: "Malta",
  },
  {
    emoji: "🇲🇺",
    key: "Mauritius",
  },
  {
    emoji: "🇲🇻",
    key: "Maldives",
  },
  {
    emoji: "🇲🇼",
    key: "Malawi",
  },
  {
    emoji: "🇲🇽",
    key: "Mexico",
  },
  {
    emoji: "🇲🇾",
    key: "Malaysia",
  },
  {
    emoji: "🇲🇿",
    key: "Mozambique",
  },
  {
    emoji: "🇳🇦",
    key: "Namibia",
  },
  {
    emoji: "🇳🇨",
    key: "New Caledonia",
  },
  {
    emoji: "🇳🇪",
    key: "Niger",
  },
  {
    emoji: "🇳🇫",
    key: "Norfolk Island",
  },
  {
    emoji: "🇳🇬",
    key: "Nigeria",
  },
  {
    emoji: "🇳🇮",
    key: "Nicaragua",
  },
  {
    emoji: "🇳🇱",
    key: "Netherlands",
  },
  {
    emoji: "🇳🇴",
    key: "Norway",
  },
  {
    emoji: "🇳🇵",
    key: "Nepal",
  },
  {
    emoji: "🇳🇷",
    key: "Nauru",
  },
  {
    emoji: "🇳🇺",
    key: "Niue",
  },
  {
    emoji: "🇳🇿",
    key: "New Zealand",
  },
  {
    emoji: "🇴🇲",
    key: "Oman",
  },
  {
    emoji: "🇵🇦",
    key: "Panama",
  },
  {
    emoji: "🇵🇪",
    key: "Peru",
  },
  {
    emoji: "🇵🇫",
    key: "French Polynesia",
  },
  {
    emoji: "🇵🇬",
    key: "Papua New Guinea",
  },
  {
    emoji: "🇵🇭",
    key: "Philippines",
  },
  {
    emoji: "🇵🇰",
    key: "Pakistan",
  },
  {
    emoji: "🇵🇱",
    key: "Poland",
  },
  {
    emoji: "🇵🇲",
    key: "St. Pierre & Miquelon",
  },
  {
    emoji: "🇵🇳",
    key: "Pitcairn Islands",
  },
  {
    emoji: "🇵🇷",
    key: "Puerto Rico",
  },
  {
    emoji: "🇵🇸",
    key: "Palestinian Territories",
  },
  {
    emoji: "🇵🇹",
    key: "Portugal",
  },
  {
    emoji: "🇵🇼",
    key: "Palau",
  },
  {
    emoji: "🇵🇾",
    key: "Paraguay",
  },
  {
    emoji: "🇶🇦",
    key: "Qatar",
  },
  {
    emoji: "🇷🇪",
    key: "Réunion",
  },
  {
    emoji: "🇷🇴",
    key: "Romania",
  },
  {
    emoji: "🇷🇸",
    key: "Serbia",
  },
  {
    emoji: "🇷🇺",
    key: "Russia",
  },
  {
    emoji: "🇷🇼",
    key: "Rwanda",
  },
  {
    emoji: "🇸🇦",
    key: "Saudi Arabia",
  },
  {
    emoji: "🇸🇧",
    key: "Solomon Islands",
  },
  {
    emoji: "🇸🇨",
    key: "Seychelles",
  },
  {
    emoji: "🇸🇩",
    key: "Sudan",
  },
  {
    emoji: "🇸🇪",
    key: "Sweden",
  },
  {
    emoji: "🇸🇬",
    key: "Singapore",
  },
  {
    emoji: "🇸🇭",
    key: "St. Helena",
  },
  {
    emoji: "🇸🇮",
    key: "Slovenia",
  },
  {
    emoji: "🇸🇯",
    key: "Svalbard & Jan Mayen",
  },
  {
    emoji: "🇸🇰",
    key: "Slovakia",
  },
  {
    emoji: "🇸🇱",
    key: "Sierra Leone",
  },
  {
    emoji: "🇸🇲",
    key: "San Marino",
  },
  {
    emoji: "🇸🇳",
    key: "Senegal",
  },
  {
    emoji: "🇸🇴",
    key: "Somalia",
  },
  {
    emoji: "🇸🇷",
    key: "Suriname",
  },
  {
    emoji: "🇸🇸",
    key: "South Sudan",
  },
  {
    emoji: "🇸🇹",
    key: "São Tomé & Príncipe",
  },
  {
    emoji: "🇸🇻",
    key: "El Salvador",
  },
  {
    emoji: "🇸🇽",
    key: "Sint Maarten",
  },
  {
    emoji: "🇸🇾",
    key: "Syria",
  },
  {
    emoji: "🇸🇿",
    key: "Swaziland",
  },
  {
    emoji: "🇹🇦",
    key: "Tristan Da Cunha",
  },
  {
    emoji: "🇹🇨",
    key: "Turks & Caicos Islands",
  },
  {
    emoji: "🇹🇩",
    key: "Chad",
  },
  {
    emoji: "🇹🇫",
    key: "French Southern Territories",
  },
  {
    emoji: "🇹🇬",
    key: "Togo",
  },
  {
    emoji: "🇹🇭",
    key: "Thailand",
  },
  {
    emoji: "🇹🇯",
    key: "Tajikistan",
  },
  {
    emoji: "🇹🇰",
    key: "Tokelau",
  },
  {
    emoji: "🇹🇱",
    key: "Timor-Leste",
  },
  {
    emoji: "🇹🇲",
    key: "Turkmenistan",
  },
  {
    emoji: "🇹🇳",
    key: "Tunisia",
  },
  {
    emoji: "🇹🇴",
    key: "Tonga",
  },
  {
    emoji: "🇹🇷",
    key: "Turkey",
  },
  {
    emoji: "🇹🇹",
    key: "Trinidad & Tobago",
  },
  {
    emoji: "🇹🇻",
    key: "Tuvalu",
  },
  {
    emoji: "🇹🇼",
    key: "Taiwan",
  },
  {
    emoji: "🇹🇿",
    key: "Tanzania",
  },
  {
    emoji: "🇺🇦",
    key: "Ukraine",
  },
  {
    emoji: "🇺🇬",
    key: "Uganda",
  },
  {
    emoji: "🇺🇲",
    key: "U.S. Outlying Islands",
  },
  {
    emoji: "🇺🇳",
    key: "United Nations",
  },
  {
    emoji: "🇺🇸",
    key: "United States",
  },
  {
    emoji: "🇺🇾",
    key: "Uruguay",
  },
  {
    emoji: "🇺🇿",
    key: "Uzbekistan",
  },
  {
    emoji: "🇻🇦",
    key: "Vatican City",
  },
  {
    emoji: "🇻🇨",
    key: "St. Vincent & Grenadines",
  },
  {
    emoji: "🇻🇪",
    key: "Venezuela",
  },
  {
    emoji: "🇻🇬",
    key: "British Virgin Islands",
  },
  {
    emoji: "🇻🇮",
    key: "U.S. Virgin Islands",
  },
  {
    emoji: "🇻🇳",
    key: "Vietnam",
  },
  {
    emoji: "🇻🇺",
    key: "Vanuatu",
  },
  {
    emoji: "🇼🇫",
    key: "Wallis & Futuna",
  },
  {
    emoji: "🇼🇸",
    key: "Samoa",
  },
  {
    emoji: "🇽🇰",
    key: "Kosovo",
  },
  {
    emoji: "🇾🇪",
    key: "Yemen",
  },
  {
    emoji: "🇾🇹",
    key: "Mayotte",
  },
  {
    emoji: "🇿🇦",
    key: "South Africa",
  },
  {
    emoji: "🇿🇲",
    key: "Zambia",
  },
  {
    emoji: "🇿🇼",
    key: "Zimbabwe",
  },
  {
    emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    key: "England",
  },
  {
    emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    key: "Scotland",
  },
  {
    emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    key: "Wales",
  },
];

export default emojis;

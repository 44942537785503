
import { defineComponent } from "vue";
import store from "@/store/index";

export default defineComponent({
  name: "ProfileHelperNode",
  data() {
    return {
      warning: false,
      username: "",
      useremail: "",
      displayColor: "",
    };
  },
  created: function () {
    console.log("get profile information");
    this.username = store.getters["user/getName"];
    this.useremail = store.getters["user/getEmail"];
    this.displayColor = store.getters["user/getSetting"]("display-color");
  },
  methods: {
    closeHelper: function () {
      store.dispatch("util/closeHelper");
    },
    saveName: function () {
      console.log("save name");
    },
    saveColor: function () {
      console.log("save colour");
    },
    openColorMenu: function () {
      console.log("open color menu");
    },
    logout: function () {
      console.log(this.$gapi);
      this.$gapi.logout();
      store.dispatch("util/closeHelper");
      store.dispatch("user/unload");
    },
    deleteAccount: function () {
      store.dispatch("user/deleteAccount");
    },
  },
});

import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "menu-item" }
const _hoisted_2 = { class: "px-12 gap-3 flex flex-wrap justify-between py-5" }
const _hoisted_3 = ["title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "",
    ref: "content",
    onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
    tabindex: "0"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        class: "p-mention mention overflow-auto scrollbar-none flex-grow",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        placeholder: "search",
        ref: "search",
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }, null, 544), [
        [_vModelText, _ctx.search]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        icon: ['fal', 'square-x'],
        class: "clickable",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.select(''))),
        style: { width: '23px', height: '23px' }
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._results, (emoji, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "icon-lg clickable",
          key: i,
          title: emoji.key,
          onClick: ($event: any) => (_ctx.select(emoji.emoji))
        }, _toDisplayString(emoji.emoji), 9, _hoisted_3))
      }), 128))
    ])
  ], 544))
}

import { defineComponent, nextTick } from "vue";
import store from "@/store/index";
import { Member, Role } from "@/store/workspace/types";

export default defineComponent({
  name: "TeamHelperNode",
  data() {
    return {
      members: [] as Array<Member>,
      invites: [] as Array<Member>,
      addInvite: false,
      invite: "",

      Role: Role,
    };
  },
  created: function () {
    this.loadTeamMembers();
    this.loadTeamInvites();
  },
  computed: {
    validEmail: function (): boolean {
      return this.invite
        .toLowerCase()
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) as any;
    },
  },
  methods: {
    copyEmail: function (email: string) {
      navigator.clipboard.writeText(email);
    },
    copyInvite: function (invite: string) {
      navigator.clipboard.writeText(
        `https://dash.tryvelocity.xyz/join/${invite}`
      );
    },
    loadTeamMembers: async function () {
      (await store.getters["workspace/team/getMembers"]).forEach(
        async (member: string) => {
          this.members.push(
            await store.getters["workspace/team/getMemberMeta"](member)
          );
        }
      );
    },
    loadTeamInvites: async function () {
      (await store.getters["workspace/team/getTeamInvites"]).forEach(
        (invite: Member) => {
          this.invites.push(invite);
        }
      );
    },
    openInvite: function () {
      this.addInvite = true;
      this.invite = "";
      nextTick(() => {
        (this.$refs["invite"] as any).focus();
      });
    },
    closeInvite: function () {
      this.addInvite = false;
      this.invite = "";
    },
    sendInvite: function () {
      if (this.validEmail) {
        const email = this.invite;
        this.invite = "sending...";
        store
          .dispatch("workspace/team/sendInvite", email)
          .then((result) => {
            this.invite = "sent 🎉";
            this.invites.push(result);
            setTimeout(() => {
              this.addInvite = false;
              this.invite = "";
            }, 1200);
          })
          .catch((result) => {
            this.invite = "we couldn't seem to send your invite";
            setTimeout(() => {
              this.invite = email;
            }, 1200);
          });
      }
    },
    revokeInvite: async function (key: string) {
      this.invites[this.invites.map((invite) => invite.id).indexOf(key)].role =
        Role.Revoked;
      store
        .dispatch("workspace/team/revokeInvite", key)
        .then(() => {
          this.invites.splice(
            this.invites.map((invite) => invite.id).indexOf(key),
            1
          );
        })
        .catch(() => {
          this.invites[
            this.invites.map((invite) => invite.id).indexOf(key)
          ].role = Role.Error;
          setTimeout(() => {
            this.invites[
              this.invites.map((invite) => invite.id).indexOf(key)
            ].role = Role.Pending;
          }, 1200);
        });
    },
    closeHelper: function () {
      store.dispatch("util/closeHelper");
    },
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "icon-sm sub"
}
const _hoisted_5 = { class: "overflow-hidden overflow-ellipsis" }
const _hoisted_6 = {
  key: 0,
  class: "menu-item text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._items, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["menu-item cursor-pointer hover:bg-warm", { 'bg-cool': i == _ctx._selected }]),
          key: item.key,
          tabindex: "0",
          onClick: ($event: any) => (_ctx.run(i))
        }, [
          (Array.isArray(item.icon))
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                icon: item.icon,
                class: "icon-sm"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (typeof item.icon == 'string')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.icon), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_5, _toDisplayString(item.title), 1)
        ], 10, _hoisted_3))
      }), 128)),
      (_ctx._items.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, " no results "))
        : _createCommentVNode("", true)
    ])
  ]))
}
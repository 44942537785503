
import { defineComponent } from "vue";
import store from "@/store/index";
import { WorkspaceMeta } from "@/store/workspace/types";
import { Page } from "@/store/pages/types";

export default defineComponent({
  name: "WorkspaceListMenu",
  data() {
    return {
      workspaces: [] as Array<any>,
    };
  },
  computed: {
    currentWorkspace: function (): string {
      return store.getters["workspace/getId"];
    },
  },
  created: function () {
    store.getters["user/getWorkspaces"].forEach(async (workspace: string) => {
      this.workspaces.push(
        await store.getters["user/getWorkspaceMeta"](workspace)
      );
      console.log(this.workspaces);
    });
  },
  mounted: function () {
    (this.$refs["content"] as any).focus();
  },
  methods: {
    createWorkspace: function () {
      store.dispatch("util/closeMenu");
      store.dispatch("workspace/create").then((id: string) => {
        this.$router
          .push({
            name: "Workspace",
            params: { workspaceId: id, pageId: "default" },
          })
          .then(() => {
            store
              .dispatch("pages/create", Page.getStarterPage())
              .then((page: Page) => {
                this.$router.push({
                  name: "Page",
                  params: { workspaceId: id, pageId: page.id },
                });
              });
          });
      });
    },
    openWorkspace: function (workspace: string) {
      store.dispatch("util/closeMenu");
      this.$router.push({
        name: "Workspace",
        params: { workspaceId: workspace },
      });
    },
    handleBlur: function (event: any) {
      if (!(this.$refs["content"] as any).contains(event.relatedTarget)) {
        store.dispatch("util/closeMenu");
      }
    },
  },
});


import { defineComponent } from "vue";
import store from "@/store/index";
import { SuggestionItem } from "@/components/editor/types";
import { EditorCommandContextMenu } from "@/store/util/types";

export default defineComponent({
  name: "EditorCommandMenu",
  computed: {
    _items: function () {
      return (
        store.getters["util/getMenu"] as EditorCommandContextMenu
      ).items();
    },
    _command: function () {
      return (store.getters["util/getMenu"] as EditorCommandContextMenu)
        .command;
    },
    _selected: function (): number {
      return (store.getters["util/getMenu"] as EditorCommandContextMenu)
        .selected;
    },
  },
  methods: {
    run: function (index: number) {
      this._command(this._items[index]);
    },
  },
});


import { defineComponent } from "vue";
import store from "@/store/index";
import { getEmojis } from "@/assets/emojis";
import { EmojiSelectionContextMenu } from "@/store/util/types";

export default defineComponent({
  name: "EmojiSelectionMenu",
  props: {
    menu: {
      type: EmojiSelectionContextMenu,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      emojis: [] as Array<any>,
    };
  },
  created: function () {
    getEmojis(this.emojis);
  },
  mounted: function () {
    (this.$refs["search"] as any).focus();
  },
  computed: {
    _results: function () {
      if (this.search == "") return this.emojis;
      return this.emojis.filter(
        (a) => 0 <= a.key.toLowerCase().search(this.search.toLowerCase())
      );
    },
  },
  methods: {
    select: function (emoji: string) {
      this.menu.select(emoji);
      store.dispatch("util/closeMenu");
    },
    handleBlur: function (event: any) {
      if (
        this.$refs["content"] &&
        !(this.$refs["content"] as any).contains(event.relatedTarget)
      ) {
        store.dispatch("util/closeMenu");
      }
    },
  },
});


import { defineComponent } from "vue"
import store from "@/store/index"
import { HighlightContextMenu } from "@/store/util/types"

export default defineComponent({
  name: "HighlightMenu",
  created: function() {
    const extensionNames = this._menu.editor.extensionManager.extensions.map((extension: any) => extension.name)
    this.acceptTypography = extensionNames.includes('serif') && extensionNames.includes('script') && extensionNames.includes('monospace')
  },
  data() {
    return {
      acceptTypography: false
    }
  },
  computed: {
    _menu: function(): HighlightContextMenu {
      return store.getters['util/getMenu'] as HighlightContextMenu
    },
    _isBold: function(): boolean {
      return this._menu.editor.isActive('bold');
    },
    _isItalic: function(): boolean {
      return this._menu.editor.isActive('italic');
    },
    _isUnderline: function(): boolean {
      return this._menu.editor.isActive('underline');
    },
    _isSerif: function(): boolean {
      return this._menu.editor.isActive('serif');
    },
    _isMonospace: function(): boolean {
      return this._menu.editor.isActive('monospace');
    },
    _isScript: function(): boolean {
      return this._menu.editor.isActive('script');
    },
    _isLink: function(): boolean {
      return this._menu.editor.isActive('link');
    },
    _linkValue: {
      get(): string {
        return this._menu.editor.getAttributes('link').href
      },
      set(newValue: string) {
        this._menu.editor.chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: newValue, target: '_blank' })
          .run()
      }
    }
  },
  methods: {
    clearFormatting: function() {
      this._menu.editor.chain().focus().clearNodes().run()
    },
    toggleBold: function() {
      this._menu.editor.chain().focus().toggleMark('bold').run()
    },
    toggleItalic: function() {
      this._menu.editor.chain().focus().toggleMark('italic').run()
    },
    toggleUnderline: function() {
      this._menu.editor.chain().focus().toggleMark('underline').run()
    },
    toggleSerif: function() {
      this._menu.editor.chain()
        .focus()
        .unsetMark('monospace').unsetMark('script')
        .toggleMark('serif')
        .run()
    },
    toggleMonospace: function() {
      this._menu.editor.chain()
        .focus()
        .unsetMark('serif').unsetMark('script')
        .toggleMark('monospace')
        .run()
    },
    toggleScript: function() {
      this._menu.editor.chain()
        .focus()
        .unsetMark('serif').unsetMark('monospace')
        .toggleMark('script')
        .run()
    },
    toggleLink: function() {
      if(this._isLink) {
        this._menu.editor.chain()
          .focus()
          .unsetLink()
          .run()
      } else {
        this._menu.editor.chain()
          .focus()
          .toggleLink({href: 'https://', target: '_blank'})
          .run()
      }
    },
    openLink: function() {
      window.open(this._linkValue)
    }
  }
})

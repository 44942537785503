
import { defineComponent } from "vue";
import store from "@/store/index";
import { Package } from "@/store/workspace/types";
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default defineComponent({
  name: "PackageHelperNode",
  components: {
    StripeCheckout,
  },
  data() {
    return {
      Package: Package,
      members: [] as Array<string>,
      cost: 0,

      publishableKey:
        "pk_test_51JUKaEIWjriAs57eD6p0It3vAfmzJujDu1bJJbna1k3THnG0b5iBjLD7zcxFhDmmWDBHnLzCRIzYL6HjPfCJ82Kc004oztIvSY",
      loading: false,
      lineItems: [
        {
          price: "price_1L90icIWjriAs57epMPppflv", // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: `'https://tryvelocity.xyz/connect/stripe/${store.getters["workspace/getId"]}'`,
      cancelURL: document.URL,
    };
  },
  created: async function () {
    const members = await store.getters["workspace/team/getMembers"];
    this.members = members;

    if (this._plan == Package.Team) {
      return members.length * 120;
    } else {
      return 0;
    }
  },
  computed: {
    _plan: function () {
      return store.getters["workspace/getPlan"];
    },
  },
  methods: {
    closeHelper: function () {
      store.dispatch("util/closeHelper");
    },
    upgradeToTeams() {
      // You will be redirected to Stripe's secure checkout page
      console.log(this.$refs);
      (this.$refs.checkoutRef as any).redirectToCheckout();
    },
  },
});
